<template>
  <div>
    <div v-if="percent == 'N/A'">{{ $t("N/A") }}</div>
    <div class="text-red-900 font-bold" v-else-if="percent == 'Past due'">
      {{ $t("Past due") }}
    </div>
    <div class="text-indigo-700 font-bold" v-else-if="percent == 100">
      {{ $t("due today") }}
    </div>
    <div v-else class="flex">
      <div
        v-if="percent > 1"
        class="bg-green-500 rounded-l h-2"
        :style="`width: ${percent}%`"
        :class="{
          'rounded-r': percent == 100
        }"
      />
      <div
        class="bg-gray-200 rounded-r h-2"
        :style="`width: ${100 - percent}%`"
        :class="{
          'rounded-l': percent < 1
        }"
      />
    </div>
    <span
      :class="{
        'text-red-900': percent == 'Past due'
      }"
    >
      {{ date }}
    </span>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ListTableDueDate",
  props: {
    date: {
      type: String,
      required: false
    },
    inserted_at: {
      type: String,
      required: true
    }
  },
  computed: {
    percent() {
      const due = moment(this.date).unix();
      const today = moment().startOf("day").unix();
      const created = moment(this.inserted_at).unix();
      if (isNaN(due)) {
        return "N/A";
      }
      if (due < today) {
        return "Past due";
      }
      return ((today - created) * 100) / (due - created);
    }
  }
};
</script>
