<template>
  <div id="app" class="flex flex-col" style="min-height: 100vh">
    <div
      class="w-screen h-full z-50 fixed top-0 opacity-80 bg-white"
      v-if="$store.state.upload.progress != 0"
      style="z-index: 10000"
    >
      <div
        class="w-screen bg-teal-500 h-2"
        :style="`width: ${$store.state.upload.progress}%`"
      />
    </div>
    <template v-if="$route.name == 'QrCodePrint'">
      <router-view :key="$route.fullPath" />
    </template>
    <template v-else>
      <div
        class="w-screen h-full z-50 fixed top-0 opacity-70 bg-white"
        style="z-index: 200"
        v-if="$store.state.progression.percentCompleted != 0"
      >
        <div
          class="w-screen bg-teal-500 h-2"
          :style="`width: ${$store.state.progression.percentCompleted}%`"
        />
        <div class="flex justify-center items-center h-full">
          <i class="fas fa-circle-notch fa-spin mr-1" />
          {{ $t("please wait...") }}
        </div>
      </div>
      <template v-if="fullLayout">
        <Nav />
        <div
          class="relative bg-blueGray-100"
          :class="{
            'md:ml-64': showSideNav,
            'md:ml-14': !showSideNav
          }"
        >
          <div
            class="hidden md:flex hover:bg-gray-100 cursor-pointer fixed top-9 z-50 bg-white border rounded-full h-7 w-7 text-center justify-center items-center shadow-lg"
            :class="{
              'left-10': !showSideNav,
              'left-60': showSideNav
            }"
            @click="$store.dispatch('accounts/toggleSideNav')"
          >
            <i
              class="fas cursor-pointer text-gray-400 text-xs font-semibold"
              :class="{
                'fa-chevron-left': $store.state.accounts.user.showSideNav,
                'fa-chevron-right': !$store.state.accounts.user.showSideNav
              }"
            />
          </div>
          <SubNav />
          <div class="h-32" />
          <router-view :key="$route.fullPath" />
        </div>
      </template>
      <router-view v-else :key="$route.fullPath" />
      <div
        class="px-4 py-2 border-t flex items-center justify-center md:hidden bg-white w-full text-center mt-auto h-full"
        ref="copyright"
      >
        <img
          alt="vault logo"
          width="50"
          class="inline mr-2"
          src="@/assets/vault_transparent_dark_logo.png"
        />
        <p class="text-xs text-blueGray-500 font-semibold fl">
          {{ $t("Copyright © ") }} {{ currentYear }}.
          {{ $t("vault solutions.") }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
// import { Socket } from "phoenix";
import Nav from "@/components/Nav";
import SubNav from "@/components/SubNav";
export default {
  components: {
    Nav,
    SubNav
  },
  computed: {
    showSideNav() {
      return this.$store.state.accounts?.user?.info?.showSideNav;
    },
    fullLayout() {
      const passNames = ["Login", "PasswordReset", "PasswordResetToken"];
      return !passNames.includes(this.$route.name);
    }
  },
  data() {
    return { currentYear: new Date().getFullYear() };
  },
  created() {
    if (this.$store.getters["accounts/isAuthenticated"]) {
      this.$store.dispatch("accounts/me");
    }
    // this.$store.dispatch("bikeLocks/getAllBikeLocks");
    this.$store.dispatch("hubs/getAllHubs");
    this.$store.dispatch("suppliers/getAllSuppliers");
    this.$store.dispatch("bikes/getAllBikes");
    this.$store.dispatch("spaces/getAllSpaces");

    // let socket = new Socket("ws://localhost:4040/socket", {
    //   params: { token: "window.userToken" }
    // });
    // socket.connect();
    // const channel = socket.channel(`room:`);
    // channel
    //   .join()
    //   .receive("ok", responsePayload => {
    //     console.log(responsePayload);
    //     // do something like display the content of responsePayload.challenge
    //   })
    //   .receive("error", resp => {
    //     console.log("Unable to join", resp);
    //   });
    // channel.on("new_bid", data => {
    //   console.log("new_bid message received", data);
    // });

    // channel.push("new_bid", { wtf: "wtf" });
  }
};
</script>
