import axios from "../axios";
import { MessageBox, Notification } from "element-ui";
import { mapObjectsById } from "./helpers";
import i18n from "../i18n";

export default {
  namespaced: true,
  state: {
    bike_damage_definitions: [],
    ticketDefinitions: [],
    components: [],
    component_damages: [],
    bike: [],
    supplier: [],
    checklist: [],
    hub: [],
    loading: false,
    error: null
  },
  getters: {
    ticketTypeByID(state) {
      return mapObjectsById(state.ticketDefinitions);
    }
  },
  mutations: {
    RESET_ERROR(state) {
      state.error = null;
    },
    GET_BIKE_DAMAGES_DEFINITIONS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_BIKE_DAMAGES_DEFINITIONS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.bike_damage_definitions = data;
    },
    GET_BIKE_DAMAGES_DEFINITIONS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bike_damage_definitions = [];
    },
    GET_HUB_DEFINITIONS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_HUB_DEFINITIONS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.hub = data;
    },
    GET_HUB_DEFINITIONS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.hub = [];
    },
    GET_CHECK_LIST_DEFINITIONS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_CHECK_LIST_DEFINITIONS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.checklist = data;
    },
    GET_CHECK_LIST_DEFINITIONS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.checklist = [];
    },
    GET_BIKE_DEFINITIONS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_BIKE_DEFINITIONS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.bike = data;
    },
    GET_BIKE_DEFINITIONS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bike = [];
    },
    GET_COMPONENTS_DEFINITIONS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_COMPONENTS_DEFINITIONS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.components = data;
    },
    GET_COMPONENTS_DEFINITIONS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.components = [];
    },
    GET_COMPONENT_DAMAGES_DEFINITIONS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_COMPONENT_DAMAGES_DEFINITIONS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.component_damages = data;
    },
    GET_COMPONENT_DAMAGES_DEFINITIONS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.component_damages = [];
    },
    GET_SUPPLIER_DEFINITIONS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_SUPPLIER_DEFINITIONS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.supplier = data;
    },
    GET_SUPPLIER_DEFINITIONS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.supplier = [];
    },
    GET_TICKETS_DEFINITIONS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_TICKETS_DEFINITIONS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.ticketDefinitions = data;
    },
    GET_TICKETS_DEFINITIONS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.ticketDefinitions = [];
    },
    ERROR_STATUS(state, error) {
      state.error = Object.entries(error).reduce((accu, [key, value]) => {
        accu = accu + `${key} ${value}`;
        return accu;
      }, "");
    }
  },
  actions: {
    getAllTypeDefinitions({ dispatch }) {
      dispatch("getAllBikeDamageDefinitions");
      dispatch("getAllTicketDefinitions");
      dispatch("getAllBikeDefinitions");
      dispatch("getAllHubDefinitions");
      dispatch("getAllSupplierDefinitions");
      dispatch("getAllCheckListDefinitions");
      dispatch("getComponentsDefinitions");
      dispatch("getComponentDamagesDefinitions");
    },
    getAllBikeDamageDefinitions: async ({ commit }) => {
      try {
        commit("GET_BIKE_DAMAGES_DEFINITIONS_REQUEST");
        const { data } = await axios.get(`/type-definitions/bike-damages`);
        commit("GET_BIKE_DAMAGES_DEFINITIONS_SUCCESS", data);
      } catch (err) {
        commit("GET_BIKE_DAMAGES_DEFINITIONS_FAILURE", err);
      }
    },
    getAllBikeDefinitions: async ({ commit }) => {
      try {
        commit("GET_BIKE_DEFINITIONS_REQUEST");
        const { data } = await axios.get(`/type-definitions/bike`);
        commit("GET_BIKE_DEFINITIONS_SUCCESS", data);
      } catch (err) {
        commit("GET_BIKE_DEFINITIONS_FAILURE", err);
      }
    },
    getAllCheckListDefinitions: async ({ commit }) => {
      try {
        commit("GET_CHECK_LIST_DEFINITIONS_REQUEST");
        const { data } = await axios.get(`/type-definitions/checklist`);
        commit("GET_CHECK_LIST_DEFINITIONS_SUCCESS", data);
      } catch (err) {
        commit("GET_CHECK_LIST_DEFINITIONS_FAILURE", err);
      }
    },
    getComponentsDefinitions: async ({ commit }) => {
      try {
        commit("GET_COMPONENTS_DEFINITIONS_REQUEST");
        const { data } = await axios.get(`/type-definitions/components`);
        commit("GET_COMPONENTS_DEFINITIONS_SUCCESS", data);
      } catch (err) {
        commit("GET_COMPONENTS_DEFINITIONS_FAILURE", err);
      }
    },
    getComponentDamagesDefinitions: async ({ commit }) => {
      try {
        commit("GET_COMPONENT_DAMAGES_DEFINITIONS_REQUEST");
        const { data } = await axios.get(`/type-definitions/component_damages`);
        commit("GET_COMPONENT_DAMAGES_DEFINITIONS_SUCCESS", data);
      } catch (err) {
        commit("GET_COMPONENT_DAMAGES_DEFINITIONS_FAILURE", err);
      }
    },
    getAllSupplierDefinitions: async ({ commit }) => {
      try {
        commit("GET_SUPPLIER_DEFINITIONS_REQUEST");
        const { data } = await axios.get(`/type-definitions/supplier`);
        commit("GET_SUPPLIER_DEFINITIONS_SUCCESS", data);
      } catch (err) {
        commit("GET_SUPPLIER_DEFINITIONS_FAILURE", err);
      }
    },
    getAllHubDefinitions: async ({ commit }) => {
      try {
        commit("GET_HUB_DEFINITIONS_REQUEST");
        const { data } = await axios.get(`/type-definitions/hub`);
        commit("GET_HUB_DEFINITIONS_SUCCESS", data);
      } catch (err) {
        commit("GET_HUB_DEFINITIONS_FAILURE", err);
      }
    },
    getAllTicketDefinitions: async ({ commit }) => {
      try {
        commit("GET_TICKETS_DEFINITIONS_REQUEST");
        const { data } = await axios.get(`/type-definitions/tickets`);
        commit("GET_TICKETS_DEFINITIONS_SUCCESS", data);
      } catch (err) {
        commit("GET_TICKETS_DEFINITIONS_FAILURE", err);
      }
    },
    updateBikeDamageDefinition: async (
      { dispatch, commit },
      type_definition
    ) => {
      try {
        await axios.patch(`/type-definitions/${type_definition.id}`, {
          type_definition
        });
        dispatch("getAllTypeDefinitions");
        return true;
      } catch (err) {
        commit("ERROR_STATUS", err.response.data.errors);
        return false;
      }
    },
    insertBikeDamageDefinition: async (
      { dispatch, commit },
      type_definition
    ) => {
      try {
        await axios.post(`/type-definitions`, { type_definition });
        dispatch("getAllTypeDefinitions");
        return true;
      } catch (err) {
        commit("ERROR_STATUS", err.response.data.errors);
        return false;
      }
    },
    deleteTypeDefinition: ({ dispatch }, { id, typeDefinition }) => {
      MessageBox.confirm(
        i18n.t(`Are you sure you want to delete the type definition?`) +
          typeDefinition,
        "Warning",
        {
          confirmButtonText: i18n.t("OK"),
          cancelButtonText: i18n.t("Cancel"),
          type: i18n.t("warning")
        }
      ).then(async () => {
        try {
          await axios.delete(`/type-definitions/${id}`);
          dispatch("getAllTypeDefinitions");
          Notification({
            title: i18n.t("Success"),
            message: i18n.t("you have deleted the type definition"),
            type: "success"
          });
        } catch (err) {
          Notification({
            title: i18n.t("Error"),
            message: i18n.t("the type definition is currently in use"),
            type: "error"
          });
        }
      });
    }
  }
};
