import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "tailwindcss/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "typeface-montserrat";
import i18n from "./i18n";
import VueApexCharts from "vue-apexcharts";
import * as VueGoogleMaps from "vue2-google-maps";
import "@fontsource/inter";
import VueSignaturePad from "vue-signature-pad";

Vue.use(VueSignaturePad);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBkF4lNl9Yx9hkqWytSt9Xhs3EFJGPkMv8",
    libraries: "places"
  }
});

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

document.title = `Vault x ${process.env.VUE_APP_PARTNER || "You"}`;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
