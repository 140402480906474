var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col",staticStyle:{"min-height":"100vh"},attrs:{"id":"app"}},[(_vm.$store.state.upload.progress != 0)?_c('div',{staticClass:"w-screen h-full z-50 fixed top-0 opacity-80 bg-white",staticStyle:{"z-index":"10000"}},[_c('div',{staticClass:"w-screen bg-teal-500 h-2",style:(("width: " + (_vm.$store.state.upload.progress) + "%"))})]):_vm._e(),(_vm.$route.name == 'QrCodePrint')?[_c('router-view',{key:_vm.$route.fullPath})]:[(_vm.$store.state.progression.percentCompleted != 0)?_c('div',{staticClass:"w-screen h-full z-50 fixed top-0 opacity-70 bg-white",staticStyle:{"z-index":"200"}},[_c('div',{staticClass:"w-screen bg-teal-500 h-2",style:(("width: " + (_vm.$store.state.progression.percentCompleted) + "%"))}),_c('div',{staticClass:"flex justify-center items-center h-full"},[_c('i',{staticClass:"fas fa-circle-notch fa-spin mr-1"}),_vm._v(" "+_vm._s(_vm.$t("please wait..."))+" ")])]):_vm._e(),(_vm.fullLayout)?[_c('Nav'),_c('div',{staticClass:"relative bg-blueGray-100",class:{
          'md:ml-64': _vm.showSideNav,
          'md:ml-14': !_vm.showSideNav
        }},[_c('div',{staticClass:"hidden md:flex hover:bg-gray-100 cursor-pointer fixed top-9 z-50 bg-white border rounded-full h-7 w-7 text-center justify-center items-center shadow-lg",class:{
            'left-10': !_vm.showSideNav,
            'left-60': _vm.showSideNav
          },on:{"click":function($event){return _vm.$store.dispatch('accounts/toggleSideNav')}}},[_c('i',{staticClass:"fas cursor-pointer text-gray-400 text-xs font-semibold",class:{
              'fa-chevron-left': _vm.$store.state.accounts.user.showSideNav,
              'fa-chevron-right': !_vm.$store.state.accounts.user.showSideNav
            }})]),_c('SubNav'),_c('div',{staticClass:"h-32"}),_c('router-view',{key:_vm.$route.fullPath})],1)]:_c('router-view',{key:_vm.$route.fullPath}),_c('div',{ref:"copyright",staticClass:"px-4 py-2 border-t flex items-center justify-center md:hidden bg-white w-full text-center mt-auto h-full"},[_c('img',{staticClass:"inline mr-2",attrs:{"alt":"vault logo","width":"50","src":require("@/assets/vault_transparent_dark_logo.png")}}),_c('p',{staticClass:"text-xs text-blueGray-500 font-semibold fl"},[_vm._v(" "+_vm._s(_vm.$t("Copyright © "))+" "+_vm._s(_vm.currentYear)+". "+_vm._s(_vm.$t("vault solutions."))+" ")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }