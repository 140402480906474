<template>
  <div
    class="rounded-full flex items-center justify-center"
    :class="{
      'h-5 w-5': isSmall,
      'h-11 w-11': !isSmall,
      'bg-blue-100 text-gray-500': bgNonWhite,
      'bg-white text-gray-400': !bgNonWhite
    }"
  >
    <img
      class="rounded-full object-cover"
      v-if="user && user.profile_image"
      :src="user.profile_image"
      :class="{
        'h-5 w-5': isSmall,
        'h-9 w-9': !isSmall
      }"
    />
    <div
      class="absolute bg-yellow-500 text-yellow-50 px-1 text-sm"
      v-else-if="is_external"
    >
      {{ $t("external") }}
    </div>
    <div
      v-else-if="user.name"
      class="uppercase font-bold flex items-center justify-center"
      :class="{
        'text-sm': isSmall,
        'text-lg': !isSmall
      }"
    >
      {{ getInitials(user.name) }}
    </div>
    <i
      v-else
      class="far fa-user-circle"
      :class="{
        'text-sm': isSmall,
        'text-3xl': !isSmall
      }"
    />
  </div>
</template>

<script>
import userMixins from "../mixins/userMixins";

export default {
  name: "ProfileImage",
  mixins: [userMixins],
  props: {
    user: {
      type: Object,
      required: false
    },
    is_external: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    bgNonWhite: {
      type: Boolean,
      default: false
    }
  }
};
</script>
