import axios from "../axios";
import { showError } from "./helpers";

export default {
  namespaced: true,
  state: {
    schedules: [],
    loading: false,
    error: null,
    loadingCreateSchedule: false,
    loadingUpdateSlot: false
  },
  mutations: {
    GET_SCHEDULES_REQUEST(state) {
      state.loading = true;
      state.error = null;
      state.schedules = [];
    },
    GET_SCHEDULES_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.schedules = data;
    },
    GET_SCHEDULES_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.schedules = [];
    },
    CREATE_SCHEDULES_REQUEST(state) {
      state.loadingCreateSchedule = true;
    },
    CREATE_SCHEDULES_SUCCESS(state) {
      state.loadingCreateSchedule = false;
    },
    CREATE_SCHEDULES_FAILURE(state) {
      state.loadingCreateSchedule = false;
    },
    UPDATE_SLOTS_REQUEST(state) {
      state.loadingUpdateSlot = true;
    },
    UPDATE_SLOTS_SUCCESS(state) {
      state.loadingUpdateSlot = false;
    },
    UPDATE_SLOTS_FAILURE(state) {
      state.loadingUpdateSlot = false;
    }
  },
  actions: {
    createSchedules: async ({ dispatch, commit }, payload) => {
      try {
        commit("CREATE_SCHEDULES_REQUEST");
        await axios.post(`/schedules`, payload);
        dispatch("getAllSchedules");
        commit("CREATE_SCHEDULES_SUCCESS");
      } catch (err) {
        commit("CREATE_SCHEDULES_FAILURE", err);
        showError(err);
      }
    },
    updateSchedule: async ({ dispatch, commit }, { scheduleId, payload }) => {
      try {
        commit("UPDATE_SLOTS_REQUEST");
        await axios.patch(`/schedules/${scheduleId}`, payload);
        dispatch("getAllSchedules");
        commit("UPDATE_SLOTS_SUCCESS");
      } catch (err) {
        commit("UPDATE_SLOTS_FAILURE", err);
        dispatch("getAllSchedules");
        showError(err);
      }
    },
    removeAgent: async ({ dispatch, commit }, { slotId, payload }) => {
      try {
        commit("UPDATE_SLOTS_REQUEST");
        await axios.patch(`/slots/${slotId}`, payload);
        dispatch("getAllSchedules");
        commit("UPDATE_SLOTS_SUCCESS");
      } catch (err) {
        commit("UPDATE_SLOTS_FAILURE", err);
        showError(err);
      }
    },
    getAllSchedules: async ({ commit }) => {
      try {
        commit("GET_SCHEDULES_REQUEST");
        const { data } = await axios.get(`/schedules`);
        commit("GET_SCHEDULES_SUCCESS", data.data);
      } catch (err) {
        commit("GET_SCHEDULES_FAILURE", err);
      }
    }
  }
};
