import axios from "../axios";
import moment from "moment";
// import { Notification } from "element-ui";
// import i18n from "../i18n";
// import { showError, mapObjectsById } from "./helpers";
// import router from "../router";

export default {
  namespaced: true,
  state: {
    invoice: {},
    invoices: [],
    loading: false,
    error: null
  },
  mutations: {
    GET_INVOICES_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_INVOICES_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.invoices = data;
    },
    GET_INVOICES_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.invoices = [];
    },
    GET_INVOICE_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_INVOICE_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.invoice = data;
    },
    GET_INVOICE_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.invoice = {};
    }
  },
  actions: {
    getAllInvoices: async ({ commit }, cb) => {
      try {
        commit("GET_INVOICES_REQUEST");
        const { data } = await axios.get(`/invoices`);
        commit("GET_INVOICES_SUCCESS", data);
        if (cb) cb(data?.data);
      } catch (err) {
        commit("GET_INVOICES_FAILURE", err);
      }
    },
    getInvoice: async ({ commit }, { id, cb }) => {
      try {
        commit("GET_INVOICE_REQUEST");
        const { data } = await axios.get(`/invoices/${id}`);
        commit("GET_INVOICE_SUCCESS", data);
        if (cb) cb(data?.data);
      } catch (err) {
        commit("GET_INVOICE_FAILURE", err);
      }
    },
    getPDFInvoice: async (_, { id }) => {
      axios({
        url: `/invoices/${id}?action=pdf`, //your url
        method: "GET",
        responseType: "blob" // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice-${moment().unix()}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
    },
    createInvoice: async ({ commit }, { invoice, cb }) => {
      try {
        commit("GET_INVOICE_REQUEST");
        const { data } = await axios.post(`/invoices`, { invoice });
        commit("GET_INVOICE_SUCCESS", data);
        if (cb) cb(data?.data);
      } catch (err) {
        commit("GET_INVOICE_FAILURE", err);
      }
    },
    updateInvoice: async ({ commit }, { invoice, id, cb }) => {
      try {
        commit("GET_INVOICE_REQUEST");
        const { data } = await axios.patch(`/invoices/${id}`, { invoice });
        commit("GET_INVOICE_SUCCESS", data);
        if (cb) cb(data?.data);
      } catch (err) {
        commit("GET_INVOICE_FAILURE", err);
      }
    },
    deleteInvoiceLine: async ({ commit }, { invoiceId, lineId, cb }) => {
      try {
        commit("GET_INVOICE_REQUEST");
        const { data } = await axios.delete(
          `/invoices/${invoiceId}/line/${lineId}`
        );
        commit("GET_INVOICE_SUCCESS", data);
        if (cb) cb(data?.data);
      } catch (err) {
        commit("GET_INVOICE_FAILURE", err);
      }
    }
  }
};
