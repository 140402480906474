<template>
  <el-dropdown class="mx-2 cursor-pointer" @command="setLang">
    <span class="el-dropdown-link">
      <i class="fas fa-language"></i>
      {{ $t($i18n.locale) }}
      <i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="lang in langs" :key="lang" :command="lang">
        {{ $t(lang) }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "LangSelect",
  data() {
    return {
      langs: ["en", "de"]
    };
  },
  methods: {
    setLang(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch("updateUserInfo/updateUserInfo", {
        notify: false,
        info: {
          ...this.$store.state.accounts.user.info,
          lang
        }
      });
    }
  }
};
</script>
