import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import router from "../router";
import { showError } from "./helpers";

const getDefaultState = () => {
  return {
    loading: false,
    error: null,
    checkList: {},
    checkLists: []
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    RESET(state) {
      Object.assign(state, getDefaultState());
    },
    GET_CHECK_LISTS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_CHECK_LISTS_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.checkLists = data;
    },
    GET_CHECK_LISTS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.checkLists = [];
    },
    GET_CHECK_LIST_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_CHECK_LIST_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.checkList = data;
    },
    GET_CHECK_LIST_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.checkList = {};
    }
  },
  actions: {
    getAllCheckLists: async ({ commit }) => {
      try {
        commit("GET_CHECK_LISTS_REQUEST");
        const { data } = await axios.get(`/checklists/all`);
        commit("GET_CHECK_LISTS_SUCCESS", data);
      } catch (err) {
        commit("GET_CHECK_LISTS_FAILURE", err);
      }
    },
    getCheckList: async ({ commit }, { id }) => {
      try {
        commit("GET_CHECK_LIST_REQUEST");
        const { data } = await axios.get(`/checklists/${id}`);
        commit("GET_CHECK_LIST_SUCCESS", data);
      } catch (err) {
        commit("GET_CHECK_LIST_FAILURE", err.response?.data);
        showError(err);
      }
    },
    createCheckList: async ({ dispatch }, check_list) => {
      try {
        const { data } = await axios.post(`/checklists`, { check_list });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Checklist has been added!"),
          type: "success"
        });
        dispatch("getCheckList", { id: data.id });
        router.push({ name: "CheckList-Detail", params: { id: data.id } });
      } catch (err) {
        showError(err);
      }
    },
    createSchedule: async ({ dispatch }, { check_list_schedule }) => {
      try {
        await axios.post(`/checklist-schedules`, {
          check_list_schedule
        });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Checklist schedule has been added!"),
          type: "success"
        });
        dispatch("getCheckList", { id: check_list_schedule.checklist_id });
      } catch (err) {
        showError(err);
      }
    },
    cloneCheckList: async ({ dispatch }, { id }) => {
      try {
        const { data } = await axios.post(`/checklists`, {
          check_list: {
            clone: id
          }
        });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Checklist has been cloned!"),
          type: "success"
        });
        dispatch("getCheckList", { id: data.id });
        router.push({ name: "CheckList-Detail", params: { id: data.id } });
      } catch (err) {
        showError(err);
      }
    },
    updateCheckList: async ({ dispatch }, { check_list, cb }) => {
      try {
        await axios.put(`/checklists/${check_list.id}`, {
          check_list
        });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Checklist has been updated!"),
          type: "success"
        });
        if (cb) cb();
        dispatch("getCheckList", { id: check_list.id });
      } catch (err) {
        showError(err);
      }
    },
    deleteCheckList: async ({ dispatch }, { check_list, cb }) => {
      try {
        await axios.delete(`/checklists/${check_list.id}`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Checklist has been delete!"),
          type: "success"
        });
        router.push({ name: "CheckList" });
        dispatch("getAllCheckLists");
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    },
    deleteSchedule: async ({ dispatch }, { schedule }) => {
      try {
        await axios.delete(`/checklist-schedules/${schedule.id}`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Checklist has been delete!"),
          type: "success"
        });
        dispatch("getCheckList", { id: schedule.checklist_id });
      } catch (err) {
        showError(err);
      }
    },
    upsertCheckListResponse: async (
      { commit },
      { check_list_responses, cb }
    ) => {
      commit("UPSERT_CHECK_LIST_RESPONSE_REQUEST");
      try {
        commit("progression/SET_PROGRESSION", 5, { root: true });
        await axios.post(`/checklist-responses`, check_list_responses, {
          onUploadProgress: progressEvent => {
            commit(
              "progression/SET_PROGRESSION",
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
              { root: true }
            );
          }
        });
        commit("progression/SET_PROGRESSION", 0, { root: true });
        commit("UPSERT_CHECK_LIST_RESPONSE_SUCCESS");
        // ElNotification({
        //   title: t("Success"),
        //   message: t("Checklist has been added!"),
        //   type: "success",
        // });
        cb();
        // dispatch("getAllCheckListItems", check_list_id);
      } catch (err) {
        console.log(err);
        commit("progression/SET_PROGRESSION", 0, { root: true });
        commit("UPSERT_CHECK_LIST_RESPONSE_FAILURE", err);
        // ElMessage.error(`Error: ${err?.response?.data?.message}`);
      }
    }
  }
};
