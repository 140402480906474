<template>
  <transition
    appear
    enter-active-class="transform transition ease-in-out duration-100 sm:duration-100"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transform transition ease-in-out duration-100 sm:duration-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: "SlideUp"
};
</script>
