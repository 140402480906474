import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Fleet from "../views/Fleet.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
    // component: Fleet
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: () => import("../views/PasswordReset.vue")
  },
  {
    path: "/password-reset/:token",
    name: "PasswordResetToken",
    component: () => import("../views/PasswordResetToken.vue")
  },
  {
    path: "/map",
    name: "Map",
    component: () => import(/* webpackChunkName: "map" */ "../views/Map.vue")
  },
  {
    path: "/user-settings",
    name: "UserSettings",
    component: () =>
      import(
        /* webpackChunkName: "user-settings" */ "../views/UserSettings.vue"
      )
  },
  {
    path: "/fleet",
    name: "Fleet",
    component: () =>
      import(/* webpackChunkName: "fleet" */ "../views/Fleet.vue")
  },
  {
    path: "/fleet/:id",
    name: "Fleet-Detail",
    component: () =>
      import(/* webpackChunkName: "fleetDetail" */ "../views/FleetDetail.vue")
  },
  // {
  //   path: "/s/:space",
  //   name: "Space",
  //   component: () =>
  //     import(/* webpackChunkName: "space" */ "../views/Space.vue")
  // },
  // {
  //   path: "/s/:space/:row",
  //   name: "Space-Detail",
  //   component: () =>
  //     import(/* webpackChunkName: "spaceDetail" */ "../views/SpaceDetail.vue")
  // },
  {
    path: "/qr/:qr_id",
    name: "QREntry",
    component: () =>
      import(/* webpackChunkName: "qrentry" */ "../views/QREntry.vue")
  },
  {
    path: "/user",
    name: "User",
    component: () => import(/* webpackChunkName: "user" */ "../views/User.vue")
  },
  {
    path: "/user/:id",
    name: "User-Detail",
    component: () =>
      import(/* webpackChunkName: "userDetail" */ "../views/UserDetail.vue")
  },
  {
    path: "/battery",
    name: "Battery",
    component: () =>
      import(/* webpackChunkName: "battery" */ "../views/Battery.vue")
  },
  {
    path: "/battery/:id",
    name: "Battery-Detail",
    component: () =>
      import(
        /* webpackChunkName: "batteryDetail" */ "../views/BatteryDetail.vue"
      )
  },
  {
    path: "/supplier",
    name: "Supplier",
    component: () =>
      import(/* webpackChunkName: "battery" */ "../views/Supplier.vue")
  },
  {
    path: "/supplier/:id",
    name: "Supplier-Detail",
    component: () =>
      import(
        /* webpackChunkName: "supplerDetail" */ "../views/SupplierDetail.vue"
      )
  },
  {
    path: "/hub",
    name: "Hub",
    component: () =>
      import(/* webpackChunkName: "battery" */ "../views/Hub.vue")
  },
  {
    path: "/hub/:id",
    name: "Hub-Detail",
    component: () =>
      import(/* webpackChunkName: "hubDetail" */ "../views/HubDetail.vue")
  },
  {
    path: "/bookings",
    name: "Booking",
    component: () =>
      import(/* webpackChunkName: "bookings" */ "../views/Bookings.vue")
  },
  {
    path: "/booking-matrix",
    name: "BookingMatrix",
    component: () =>
      import(
        /* webpackChunkName: "bookingMatrix" */ "../views/BookingMatrix.vue"
      )
  },
  // {
  //   path: "/bookings/new",
  //   name: "NewBooking",
  //   component: () =>
  //     import(/* webpackChunkName: "new-bookings" */ "../views/NewBooking.vue")
  // },
  // {
  //   path: "/checkout-monitoring",
  //   name: "CheckoutMonitoring",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "checkout-monitoring" */ "../views/CheckoutMonitoring.vue"
  //     )
  // },
  {
    path: "/invoices",
    name: "Invoices",
    component: () =>
      import(/* webpackChunkName: "invoices" */ "../views/Invoices.vue")
  },
  {
    path: "/invoices/:id",
    name: "Invoice",
    component: () =>
      import(/* webpackChunkName: "invoice" */ "../views/Invoice.vue")
  },
  {
    path: "/labels",
    name: "Label",
    component: () =>
      import(/* webpackChunkName: "labels-detail" */ "../views/LabelDetail.vue")
  },
  {
    path: "/groups",
    name: "Group",
    component: () =>
      import(/* webpackChunkName: "group" */ "../views/Group.vue")
  },
  {
    path: "/type-definitions",
    name: "TypeDefinition",
    component: () =>
      import(
        /* webpackChunkName: "labels-detail" */ "../views/TypeDefinitions.vue"
      )
  },
  {
    path: "/ticket",
    name: "Ticket",
    component: () =>
      import(/* webpackChunkName: "ticket" */ "../views/Ticket.vue")
  },
  {
    path: "/ticket/new",
    name: "NewTicket",
    component: () =>
      import(/* webpackChunkName: "ticket" */ "../views/NewTicket.vue")
  },
  {
    path: "/ticket/:id",
    name: "Ticket-Detail",
    component: () =>
      import(
        /* webpackChunkName: "ticket-detail" */ "../views/TicketDetail.vue"
      )
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: () =>
      import(/* webpackChunkName: "permissions" */ "../views/Permissions.vue")
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () =>
      import(/* webpackChunkName: "schedule" */ "../views/Schedule.vue")
  },
  {
    path: "/damage",
    name: "Damage",
    component: () =>
      import(/* webpackChunkName: "damage" */ "../views/Damage.vue")
  },
  {
    path: "/asset",
    name: "Asset",
    component: () =>
      import(/* webpackChunkName: "asset" */ "../views/Asset.vue")
  },
  {
    path: "/asset/:id",
    name: "Asset-Detail",
    component: () =>
      import(/* webpackChunkName: "asset-detail" */ "../views/AssetDetail.vue")
  },
  {
    path: "/checklist",
    name: "CheckList",
    component: () =>
      import(/* webpackChunkName: "checklist" */ "../views/CheckList.vue")
  },
  {
    path: "/checklist/new",
    name: "New-CheckList-Detail",
    component: () =>
      import(
        /* webpackChunkName: "new-checklist-detail" */ "../views/NewCheckListDetail.vue"
      )
  },
  {
    path: "/checklist/:id",
    name: "CheckList-Detail",
    component: () =>
      import(
        /* webpackChunkName: "checklist-detail" */ "../views/CheckListDetail.vue"
      )
  },
  {
    path: "/checklist/:id/results",
    name: "CheckListResult",
    component: () =>
      import(
        /* webpackChunkName: "checklist-result" */ "../views/CheckListResults.vue"
      )
  },
  {
    path: "/qr-codes",
    name: "QrCode",
    component: () => import("../views/QrCodes.vue")
  },
  {
    path: "/alerts",
    name: "Alert",
    component: () => import("../views/Alerts.vue")
  },
  // {
  //   path: "/email-templates",
  //   name: "EmailTemplate",
  //   component: () => import("../views/EmailTemplates.vue")
  // },
  {
    path: "/qr-codes/print/:type",
    name: "QrCodePrint",
    component: () => import("../views/QrCodePrint.vue")
  }
  // {
  //   path: "/checklist-results/:id",
  //   name: "CheckListResult-Detail",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "checklist-result-detail" */ "../views/CheckListResultDetail.vue"
  //     )
  // }
  // {
  //   path: "/connect",
  //   name: "GoogleConnect",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "google-connect" */ "../views/GoogleConnect.vue"
  //     )
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === "PasswordReset" || to.name === "PasswordResetToken") {
    next();
  } else if (
    to.name !== "Login" &&
    !store.getters["accounts/isAuthenticated"]
  ) {
    next({ name: "Login", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
