import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError } from "./helpers";

export default {
  namespaced: true,
  state: {
    companies: [],
    company: {},
    loading: false,
    error: null
  },
  mutations: {
    GET_MY_COMPANY_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_MY_COMPANY_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.company = data;
    },
    GET_MY_COMPANY_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.company = {};
    },
    GET_COMPANIES_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_COMPANIES_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.companies = data;
    },
    GET_COMPANIES_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.companies = [];
    }
  },
  actions: {
    getAllCompanies: async ({ commit }) => {
      try {
        commit("GET_COMPANIES_REQUEST");
        const { data } = await axios.get(`/companies`);
        commit("GET_COMPANIES_SUCCESS", data);
      } catch (err) {
        commit("GET_COMPANIES_FAILURE", err);
      }
    },
    getMyCompany: async ({ commit }, { cb }) => {
      try {
        commit("GET_MY_COMPANY_REQUEST");
        const { data } = await axios.get(`/companies/me`);
        commit("GET_MY_COMPANY_SUCCESS", data);
        if (cb) cb();
      } catch (err) {
        commit("GET_MY_COMPANY_FAILURE", err);
      }
    },
    updateCompany: async ({ dispatch }, company) => {
      try {
        await axios.patch(`/companies/me`, { company });
        dispatch("getMyCompany", {});
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The company has been updated!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    },
    removeLogo: async ({ dispatch }, { company, cb }) => {
      try {
        await axios.patch(`/companies/me`, { company });
        dispatch("getMyCompany", {});
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The company has been updated!"),
          type: "success"
        });
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    }
  }
};
