import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    bike: {}
  },
  mutations: {
    UPDATE_BIKE_REQUEST(state) {
      state.loading = true;
      state.error = null;
      state.bike = {};
    },
    UPDATE_BIKE_SUCCESS(state, bike) {
      state.loading = false;
      state.error = null;
      state.bike = bike;
    },
    UPDATE_BIKE_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bike = {};
    }
  },
  actions: {
    updateBike: async ({ commit }, { id, notes }) => {
      try {
        commit("UPDATE_BIKE_REQUEST");
        const { data } = await axios.put(`/bikes/${id}`, {
          action: "add_notes",
          bike: {
            notes
          }
        });
        commit("UPDATE_BIKE_SUCCESS", data);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your bike notes has been updated!"),
          type: "success"
        });
      } catch (err) {
        commit("UPDATE_BIKE_FAILURE", err);
        // showError(err);
      }
    }
  }
};
