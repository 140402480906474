<template>
  <div class="h-full">
    <h2 class="text-blueGray-400 uppercase font-semibold text-xs">
      {{ $t("ASSET STATUS") }}
    </h2>
    <div class="flex justify-center items-center h-full flex-grow">
      <i v-if="loading" class="fas fa-sync fa-spin" />
      <div v-else-if="assets && assets.length > 0">
        <apexchart
          :key="`${assets.length}-${assetFilters.length}-filteredAssets`"
          type="donut"
          height="350"
          :options="chart.chartOptions"
          :series="chart.series"
        />
        <el-select
          class="w-full"
          v-model="assetFilters"
          multiple
          collapse-tags
          clearable=""
          :placeholder="$t('Select')"
        >
          <el-option
            v-for="item in $store.state.typeDefinitions.bike"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <h2 v-else class="font-semibold text-lg">
        🥺 {{ $t("no assets in the system") }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetStatusPie",
  props: {
    loading: {
      type: Boolean,
      defualt: false
    },
    assets: {
      type: Array,
      require: true
    }
  },
  computed: {
    filteredAssets() {
      if (this.assetFilters.length == 0) return this.assets;
      return this.assets.filter(a => this.assetFilters.includes(a.type_id));
    },
    chart() {
      const { series, colors, labels } = this.getPieChartData(
        this.filteredAssets
      );
      return {
        series,
        chartOptions: {
          fill: { colors },
          colors,
          chart: {
            type: "donut",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: "thin"
          },
          labels,
          legend: {
            position: "bottom",
            offsetY: 0
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                }
              }
            }
          ]
        }
      };
    }
  },
  data() {
    return {
      assetFilters: []
    };
  },
  methods: {
    getPieChartData(items) {
      const statusDistribution = items.reduce((accu, item) => {
        const statusName = item?.status?.name ? item?.status?.name : "No type";
        if (statusName in accu) {
          accu[statusName].tally = accu[statusName].tally + 1;
        } else {
          const color = item?.status?.color ? item?.status?.color : "#e3e3e3";
          accu[statusName] = {
            tally: 1,
            color
          };
        }
        return accu;
      }, {});

      return {
        labels: Object.keys(statusDistribution),
        series: Object.values(statusDistribution).map(({ tally }) => tally),
        colors: Object.values(statusDistribution).map(({ color }) => color)
      };
    }
  }
};
</script>
