import moment from "moment";

export default {
  methods: {
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    convertMsToMinutesSeconds(milliseconds) {
      if (milliseconds == null || milliseconds == undefined) return "-";
      const minutes = Math.floor(milliseconds / 60000);
      const seconds = Math.round((milliseconds % 60000) / 1000);

      return seconds === 60
        ? `${minutes + 1}:00`
        : `${minutes}:${this.padTo2Digits(seconds)}`;
    },
    getWeekDayAbbrev(date) {
      if (moment(date).isValid()) {
        return moment(date).format("dd");
      }
      return "n/a";
    },
    getDayOnly(date) {
      if (moment(date).isValid()) {
        return moment(date).format("DD");
      }
      return "n/a";
    },
    getDateOnly(date) {
      if (moment(date).isValid()) {
        return moment(date).format("DD.MM.yyyy");
      }
      return "n/a";
    },
    getDateNoYearOnly(date) {
      if (moment(date).isValid()) {
        return moment(date).format("DD.MM");
      }
      return "n/a";
    },
    getFromNowDateTime(dt) {
      return moment(dt).fromNow();
    },
    getDatetime(ts) {
      return ts;
    },
    getTimeUnit(mins) {
      if (!mins) {
        return "";
      }
      if (mins > 60) {
        return "hours";
      }
      return "minutes";
    },
    getTimeNoUnit(mins) {
      if (!mins) {
        return "";
      }
      // if (mins > 480) {
      //   return mins / 480 + " days";
      // }
      if (mins > 60) {
        return parseInt(mins / 60);
      }
      return mins;
    },
    formatTime(dt) {
      if (!dt) return "-";
      return new Intl.DateTimeFormat("de", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      }).format(new Date(dt));
    },
    timeOnly(dt) {
      if (!dt) return "-";
      return new Intl.DateTimeFormat("de", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      }).format(new Date(dt));
    },
    getTimeFormat(mins) {
      const unit = this.getTimeUnit(mins);
      const time = this.getTimeNoUnit(mins);
      return time + " " + unit;
    },
    getAddress({ number, street, city, state, postal_code, country }) {
      let address = street ? `${street}` : "";
      address = number ? `${address} ${number}` : address;
      address = address.length > 0 ? `${address},` : address;
      address = postal_code ? `${address} ${postal_code}` : address;
      address = city ? `${address} ${city}` : address;
      address = state ? `${address} ${state}` : address;
      address = country ? `${address} ${country}` : address;
      return address;
    }
  }
};
