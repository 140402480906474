import axios from "../axios";
// import { Notification } from "element-ui";
// import i18n from "../i18n";
import { attachFromAndToUrl } from "./helpers";

const getDefaultState = () => {
  return {
    loading: false,
    error: null,
    checkListResult: {},
    checkListResults: []
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    RESET(state) {
      Object.assign(state, getDefaultState());
    },
    GET_CHECK_LIST_RESULTS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_CHECK_LIST_RESULTS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.checkListResults = data;
    },
    GET_CHECK_LIST_RESULTS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.checkListResults = [];
    }
  },
  actions: {
    getPureCheckListResults: async ({ rootState, commit }) => {
      const url = attachFromAndToUrl(
        `/checklists-results`,
        rootState.dateRange,
        rootState.selectedHubs
      );
      try {
        commit("GET_CHECK_LIST_RESULTS_REQUEST");
        const { data } = await axios.get(url);
        commit("GET_CHECK_LIST_RESULTS_SUCCESS", data);
      } catch (err) {
        commit("GET_CHECK_LIST_RESULTS_FAILURE", err);
      }
    },
    getAllCheckListResults: async ({ commit }, id) => {
      // this is to get all checklist results of a checklist
      try {
        commit("GET_CHECK_LIST_RESULTS_REQUEST");
        const { data } = await axios.get(
          `/checklists-results/${id}/all-results`
        );
        commit("GET_CHECK_LIST_RESULTS_SUCCESS", data);
      } catch (err) {
        commit("GET_CHECK_LIST_RESULTS_FAILURE", err);
      }
    }
  }
};
