import axios from "../axios";
import { attachFromAndToUrl, showError } from "./helpers";

export default {
  namespaced: true,
  state: {
    checkouts: [],
    loading: false,
    error: null
  },
  mutations: {
    GET_CHECKOUTS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_CHECKOUTS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.checkouts = data;
    },
    GET_CHECKOUTS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.checkouts = [];
    }
  },
  actions: {
    getAllCheckouts: async ({ rootState, commit }) => {
      const url = attachFromAndToUrl(
        `/checkouts`,
        rootState.dateRange,
        rootState.selectedHubs
      );
      try {
        commit("GET_CHECKOUTS_REQUEST");
        const { data } = await axios.get(url);
        commit("GET_CHECKOUTS_SUCCESS", data);
      } catch (err) {
        commit("GET_CHECKOUTS_FAILURE", err);
      }
    },
    returnCheckout: async (_, { checkout, cb }) => {
      try {
        await axios.put(`/checkouts/${checkout.id}`, {
          checkout: {
            action: "returning"
          }
        });
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    },
    grabCheckout: async (_, { checkout, cb }) => {
      try {
        await axios.post(`/checkouts`, checkout);
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    },
    updateGrabCheckout: async (_, { checkout, cb, id }) => {
      try {
        await axios.patch(`/checkouts/${id}`, checkout);
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    }
  }
};
