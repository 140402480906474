<template>
  <div
    class="bg-white rounded mx-4 mb-6 px-5 py-3 flex flex-col items-center md:flex-row justify-between shadow-xl"
  >
    <div class="mb-4 md:mb-0">
      <!-- <el-select
        v-model="$store.state.selectedHubs"
        multiple
        class="w-96 ml-2"
        collapse-tags
        placeholder="Select"
        @visible-change="visibleChange"
        @remove-tag="removeTag"
        size="small"
      >
        <el-option
          v-for="hub in $store.state.hubs.hubs"
          :key="hub.id"
          :label="hub.name"
          :value="hub.id"
        >
        </el-option>
      </el-select> -->
      <div class="flex flex-wrap mt-2">
        <div
          @click="toggleHub(h)"
          class="mx-1 my-1"
          v-for="h in $store.state.hubs.hubs"
          :key="h.id"
        >
          <span
            class="border px-2 py-1 truncate rounded cursor-pointer text-xs"
            :class="{
              'bg-gray-600 text-gray-50': $store.state.selectedHubs.includes(
                h.id
              )
            }"
          >
            {{ h.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex items-center">
      <slot name="right" />
      <el-date-picker
        v-if="!noDate"
        style="width: 24rem"
        @change="$emit('getData')"
        v-model="dateRange"
        type="daterange"
        align="right"
        :start-placeholder="$t('Start Date')"
        :end-placeholder="$t('End Date')"
        :picker-options="pickerOptions"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpBar",
  props: {
    noDate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timeout: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  methods: {
    toggleHub(hub) {
      if (this.allSelected) {
        this.$store.state.selectedHubs = [];
      }
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("getData");
      }, 300);
      if (this.$store.state.selectedHubs.includes(hub.id)) {
        this.$store.state.selectedHubs = this.$store.state.selectedHubs.filter(
          h => h !== hub.id
        );
      } else {
        this.$store.state.selectedHubs.push(hub.id);
      }
    },
    removeTag() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("getData");
      }, 1500);
    },
    visibleChange(isOpen) {
      // when the visiable select closed
      if (!isOpen) {
        this.$emit("getData");
      }
    }
  },
  computed: {
    allSelected() {
      if (
        this.$store.state.hubs.hubs.length ==
        this.$store.state.selectedHubs.length
      ) {
        return true;
      }
      return false;
    },
    dateRange: {
      get() {
        return this.$store.state.dateRange;
      },
      set(val) {
        this.$store.commit("SET_DATE_RANGE", val);
      }
    }
  }
};
</script>
