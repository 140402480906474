import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import accounts from "./accounts";
import bikes from "./bikes";
import bikeLocks from "./bikeLocks";
import hubs from "./hubs";
import suppliers from "./suppliers";
import bike from "./bike";
import users from "./users";
import shifts from "./shifts";
import batteries from "./batteries";
import updateUserInfo from "./updateUserInfo";
import updateBike from "./updateBike";
import user from "./user";
import damages from "./damages";
import tags from "./tags";
import typeDefinitions from "./typeDefinitions";
import exportData from "./exportData";
import tickets from "./tickets";
import schedule from "./schedule";
import upload from "./upload";
import settings from "./settings";
import resetPassword from "./resetPassword";
import assets from "./assets";
import checkLists from "./checkLists";
import checkListItems from "./checkListItems";
import checkListResults from "./checkListResults";
import companies from "./companies";
import groups from "./groups";
import progression from "./progression";
import checkouts from "./checkouts";
import spaces from "./spaces";
import invoices from "./invoices";
// import gsheets from "./gsheets";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firstLoad: true,
    selectedHubs: [],
    dateRange: [moment().subtract(7, "day").startOf("day").toDate(), new Date()]
  },
  mutations: {
    SET_DATE_RANGE(state, dateRange) {
      state.dateRange = dateRange;
    },
    SET_HUBS(state, hubs) {
      if (state.firstLoad) {
        state.selectedHubs = hubs;
        state.firstLoad = false;
      }
    }
  },
  actions: {},
  modules: {
    accounts,
    bikes,
    bikeLocks,
    hubs,
    suppliers,
    bike,
    users,
    shifts,
    updateUserInfo,
    updateBike,
    batteries,
    damages,
    tags,
    user,
    typeDefinitions,
    exportData,
    tickets,
    upload,
    settings,
    schedule,
    resetPassword,
    assets,
    checkLists,
    checkListItems,
    checkListResults,
    companies,
    groups,
    progression,
    checkouts,
    spaces,
    invoices
    // gsheets
  }
});
