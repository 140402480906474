import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import router from "../router";
import { showError } from "./helpers";

const getDefaultState = () => {
  return {
    bike: {},
    loading: false,
    error: null
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    RESET_BIKE(state) {
      Object.assign(state, getDefaultState());
    },
    GET_BIKE_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_BIKE_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.bike = data;
    },
    GET_BIKE_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bike = {};
    },
    UPDATE_BIKE_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    UPDATE_BIKE_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.bike = data;
    },
    UPDATE_BIKE_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
    }
  },
  actions: {
    // fix in future
    getBikeWithCb: async ({ commit }, { id, cb }) => {
      try {
        commit("GET_BIKE_REQUEST");
        const { data } = await axios.get(`/bikes/${id}`);
        commit("GET_BIKE_SUCCESS", data);
        cb(data);
      } catch (err) {
        commit("GET_BIKE_FAILURE", err);
      }
    },
    getBike: async ({ commit }, id) => {
      try {
        commit("GET_BIKE_REQUEST");
        const { data } = await axios.get(`/bikes/${id}`);
        commit("GET_BIKE_SUCCESS", data);
        return true;
      } catch (err) {
        commit("GET_BIKE_FAILURE", err);
        return false;
      }
    },
    updateDamageStatus: async (
      { commit, dispatch },
      { id, damageId, status }
    ) => {
      try {
        commit("UPDATE_BIKE_DAMAGE_REQUEST");

        await axios.put(`/bike-damages/${damageId}`, {
          bike_damage: {
            bike_id: id,
            damage_status: status
          }
        });

        dispatch("getBike", id);

        // commit("UPDATE_BIKE_DAMAGE_SUCCESS", data);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your asset damages has been updated!"),
          type: "success"
        });
      } catch (err) {
        Notification({
          title: i18n.t("Error"),
          message: i18n.t("Something!"),
          type: "error"
        });
        commit("UPDATE_BIKE_DAMAGE_FAILURE", err);
        // showError(err);
      }
    },
    createBike: async ({ dispatch }, bike) => {
      try {
        // commit("UPDATE_BIKE_REQUEST");
        const { data } = await axios.post(`/bikes`, { bike });
        dispatch("getBike", data.data.id);
        router.push({
          name: "Fleet-Detail",
          params: {
            id: data.data.id
          }
        });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your asset has been created!"),
          type: "success"
        });
      } catch (err) {
        // commit("UPDATE_BIKE_FAILURE", err);
        showError(err);
      }
    },
    cloneBike: async ({ dispatch }, bike) => {
      try {
        // commit("UPDATE_BIKE_REQUEST");
        const { data } = await axios.post(`/bikes`, {
          bike: {
            clone: bike.id
          }
        });
        dispatch("getBike", data.data.id);
        router.push({
          name: "Fleet-Detail",
          params: {
            id: data.data.id
          }
        });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your asset has been cloned!"),
          type: "success"
        });
      } catch (err) {
        // commit("UPDATE_BIKE_FAILURE", err);
        showError(err);
      }
    },
    updateBike: async ({ commit }, bike) => {
      try {
        commit("UPDATE_BIKE_REQUEST");
        const { id } = bike;
        const { data } = await axios.put(`/bikes/${id}`, { bike });
        commit("UPDATE_BIKE_SUCCESS", data);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your asset notes has been updated!"),
          type: "success"
        });
      } catch (err) {
        commit("UPDATE_BIKE_FAILURE", err);
        showError(err);
      }
    },
    setStartEnd: async ({ dispatch }, { bike, cb }) => {
      try {
        const { id } = bike;
        await axios.put(`/bikes/${id}`, { bike });
        dispatch("getBike", id);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your checklist has been added!"),
          type: "success"
        });
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    },
    deleteBike: async ({ dispatch }, { bike, cb }) => {
      try {
        await axios.delete(`/bikes/${bike.id}`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("asset has been delete!"),
          type: "success"
        });
        dispatch("bikes/getAllBikes", null, { root: true });
        router.push({ name: "Fleet" });
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    }
  }
};
