import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError, attachFromAndToUrl } from "./helpers";

const getDefaultState = () => {
  return {
    loading: false,
    error: null,
    damages: []
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    DAMAGE_RESET(state) {
      Object.assign(state, getDefaultState());
    },
    DAMAGE_CREATE_REQUEST(state) {
      state.loading = true;
    },
    DAMAGE_CREATE_SUCCESS(state) {
      Object.assign(state, getDefaultState());
    },
    DAMAGE_CREATE_FAILURE(state, error) {
      state.error = error;
      state.loading = false;
    },
    DAMAGES_REQUEST(state) {
      state.loading = true;
    },
    DAMAGES_SUCCESS(state, data) {
      state.damages = data;
      state.loading = false;
      state.error = false;
    },
    DAMAGES_FAILURE(state, error) {
      state.damages = [];
      state.loading = false;
      state.error = error;
    }
  },
  actions: {
    getDamages: async ({ rootState, commit }, options) => {
      const endpointUrl = `/bike-damages`;
      let url = attachFromAndToUrl(
        endpointUrl,
        rootState.dateRange,
        rootState.selectedHubs
      );
      if (options?.resolved) {
        const concater = endpointUrl == url ? "?" : "&";
        url = `${url}${concater}resolved=${options?.resolved}`;
      }
      try {
        commit("DAMAGES_REQUEST");
        const { data } = await axios.get(url);
        commit("DAMAGES_SUCCESS", data);
      } catch (err) {
        commit("DAMAGES_FAILURE", err.response?.data);
      }
    },
    createDamage: async ({ commit, dispatch }, damage) => {
      try {
        commit("DAMAGE_CREATE_REQUEST");
        const { data } = await axios.post(`/bike-damages`, damage);
        commit("DAMAGE_CREATE_SUCCESS");
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Bike damage has been added!"),
          type: "success"
        });
        await dispatch("bike/getBike", data.data.bike.id, { root: true });
        return true;
      } catch (err) {
        commit("DAMAGE_CREATE_FAILURE", err.response?.data);
        showError(err);
        return false;
      }
    },
    updateDamage: async ({ dispatch }, { bike_damage }) => {
      try {
        // commit("DAMAGE_CREATE_REQUEST");
        const { data } = await axios.put(`/bike-damages/${bike_damage.id}`, {
          bike_damage
        });
        // commit("DAMAGE_CREATE_SUCCESS");
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Bike damage has been added!"),
          type: "success"
        });
        await dispatch("bike/getBike", data.data.bike.id, { root: true });
        return true;
      } catch (err) {
        // commit("DAMAGE_CREATE_FAILURE", err.response?.data);
        showError(err);
        return false;
      }
    },
    updateDamageImages: async ({ dispatch }, { payload, id }) => {
      try {
        // commit("DAMAGE_CREATE_REQUEST");
        const { data } = await axios.put(`/bike-damages/${id}`, payload);
        // commit("DAMAGE_CREATE_SUCCESS");
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Bike damage images has been added!"),
          type: "success"
        });
        await dispatch("bike/getBike", data.data.bike.id, { root: true });
        return true;
      } catch (err) {
        // commit("DAMAGE_CREATE_FAILURE", err.response?.data);
        showError(err);
        return false;
      }
    }
  }
};
