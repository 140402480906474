export default {
  computed: {
    isSuperAdmin() {
      return this.$store.state?.accounts?.user.is_superuser;
    },
    myId() {
      return this.$store.state?.accounts?.user?.id;
    },
    accountType() {
      return this.$store.state?.accounts?.user?.account_type
        ? this.$store.state?.accounts?.user?.account_type
        : "";
    },
    isAdminOrManager() {
      return ["admin", "manager"].includes(this.accountType);
    }
  },
  methods: {
    getInitials(name) {
      try {
        const i = name.split(" ");
        const first = i?.[0] ? i?.[0].charAt(0) : "";
        const second = i?.[1] ? i?.[1].charAt(0) : "";
        return `${first}${second}`;
      } catch {
        //
      }
      return "";
    }
  }
};
