<template>
  <div
    class="w-full mb-4"
    :class="{ 'px-4': usePadding && !fullwidth, 'mx-4': !usePadding }"
  >
    <div
      class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white"
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div
            class="relative w-full px-4 max-w-full flex-grow flex-1 flex items-center"
          >
            <h3 class="font-semibold text-lg text-blueGray-700">
              {{ tableName }}
              <span class="text-blue-500 text-sm font-bold">
                ({{ sortedTableData.length }}/{{ tableData.length }})
              </span>
            </h3>
            <div
              v-if="searchable"
              class="bg-gray-100 py-2 px-3 ml-8 rounded-3xl md:items-center hidden md:flex"
              :class="{
                'border-cyan-600 border': searchFocus,
                'border-gray-100 border': !searchFocus
              }"
            >
              <i class="fas fa-search"></i>
              <input
                class="bg-gray-100 focus:outline-none pl-2"
                v-model="searchVal"
                @focus="searchFocus = true"
                @blur="searchFocus = false"
              />
            </div>
          </div>
          <slot name="top-right-corner">
            <router-link
              v-if="createName != '' && isAdminOrManager"
              :to="{ name: createName, params: { id: 'new' } }"
            >
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                {{ $t("add new") }}
              </button>
            </router-link>
            <!-- <i class="fas fa-ellipsis-v ml-2" v-if="$route.name === 'User'" /> -->
          </slot>
        </div>
      </div>
      <div class="block w-full overflow-x-auto" :style="getHeight()">
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <template v-for="h in headers">
                <th
                  :key="`${h.key}-${h.type}`"
                  v-if="h.type != 'hidden'"
                  class="sticky top-0 align-middle border-solid text-xs uppercase border-l-0 border-r whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500"
                >
                  <div class="flex items-center px-6 py-3 border-t border-b">
                    <div
                      :class="{
                        'text-cyan-600 border-b-2 border-cyan-600':
                          h.key == sortKey,
                        'border-b-2 border-blueGray-50': h.key != sortKey
                      }"
                    >
                      <span
                        :class="{
                          'cursor-pointer': !h.noSort
                        }"
                        @click="setSort(h)"
                      >
                        {{ $t(h.name) }}
                      </span>
                      <el-popover
                        v-if="h.filter"
                        placement="bottom"
                        trigger="click"
                      >
                        <div
                          class="flex justify-between cursor-pointer py-2 px-2 hover:bg-blueGray-100 rounded"
                          v-for="filter in getFilterables(h)"
                          :key="filter"
                          @click="toggleFilterKey(h, filter)"
                        >
                          {{ filter }}
                          <i
                            class="fas fa-check text-green-500 ml-1"
                            v-if="
                              filterKey[h.key] &&
                              filterKey[h.key].includes(filter)
                            "
                          />
                        </div>
                        <i
                          slot="reference"
                          class="fas fa-filter"
                          :class="{
                            'text-cyan-600': filterKey[h.key]
                          }"
                        />
                      </el-popover>
                    </div>
                    <i
                      @click="setSort(h)"
                      v-if="sortDirection == 'ASC' && h.key == sortKey"
                      class="fas fa-long-arrow-alt-down text-cyan-600 cursor-pointer"
                    />
                    <i
                      @click="setSort(h)"
                      v-if="sortDirection == 'DESC' && h.key == sortKey"
                      class="fas fa-long-arrow-alt-up text-cyan-600 cursor-pointer"
                    />
                    <i
                      @click="setSort(h)"
                      v-if="h.key != sortKey"
                      class="fas fa-long-arrow-alt-down text-blueGray-50 cursor-pointer"
                    />
                  </div>
                </th>
              </template>
            </tr>
          </thead>
          <tbody v-if="loading" class="absolute top-1/2 left-1/2">
            <i class="fas fa-sync fa-spin"></i>
          </tbody>
          <tbody
            v-else-if="sortedTableData.length == 0"
            class="w-full absolute top-1/2 text-center"
          >
            <p class="text-lg font-bold">
              {{ $t("no data avaliable") }}
            </p>
          </tbody>
          <tbody v-else>
            <tr
              v-for="(d, index) in sortedTableData"
              :key="`${d}-${index}-row`"
              :class="{ 'bg-blueGray-100': index % 2 != 0 }"
              @click="handleRowClick(d)"
              class="cursor-pointer border-b"
            >
              <template v-for="(h, hidx) in headers">
                <td
                  v-if="h.type != 'hidden'"
                  :key="`${h.key}-${h.type}-${index}-col`"
                  class="border-t-0 px-6 align-middle border-l-0 border-r text-xs whitespace-nowrap p-4"
                >
                  <div v-if="h.type == 'getValue'">
                    {{ h.getValue(d) }}
                  </div>
                  <div v-else-if="h.type == 'action'" class="flex">
                    <span
                      class="mr-1 cursor-pointer"
                      v-for="a in h.actions"
                      :key="a.name"
                      @click="$event => a.action($event, d)"
                    >
                      <i class="ml-3" :class="a.icon" /> {{ $t(a.name) }}
                    </span>
                  </div>
                  <div v-else-if="h.type == 'is_closed'">
                    <span
                      v-if="getVal(h.key, d)"
                      class="text-rose-600 flex items-center"
                    >
                      <i class="fas fa-window-close text-lg mr-1" />
                      {{ $t("closed") }}
                    </span>
                    <span v-else class="text-emerald-600 flex items-center">
                      <i class="fas fa-check-circle text-lg mr-1" />
                      {{ $t("open") }}
                    </span>
                  </div>
                  <div v-else-if="h.type == 'date'">
                    {{ h.getDate(getVal(h.key, d)) }}
                  </div>
                  <div v-else-if="h.type == 'ms'">
                    {{ convertMsToMinutesSeconds(getVal(h.key, d)) }}
                  </div>
                  <div v-else-if="h.type == 'fromNowDate'">
                    {{ getFromNowDateTime(getVal(h.key, d)) }}
                  </div>
                  <div v-else-if="h.type == 'check-action'">
                    <i
                      v-if="selectedRows[d[h.key]] == true"
                      class="fas fa-check-square mr-2 text-blueGray-600"
                      @click="h.handleClick(d[h.key])"
                    />
                    <i
                      v-else
                      class="fas fa-square mr-2 text-blueGray-300"
                      @click="h.handleClick(d[h.key])"
                    />
                  </div>
                  <span
                    v-else-if="h.type == 'published'"
                    class="flex items-center font-semibold"
                  >
                    <!-- {{ getVal(h.key, d) }} -->
                    <span class="flex items-center" v-if="getVal(h.key, d)">
                      <i
                        class="fas fa-check-circle text-green-700 text-lg mr-1"
                      />
                      {{ $t("published") }}
                    </span>
                    <span class="flex items-center" v-if="!getVal(h.key, d)">
                      <i
                        class="fas fa-times-circle text-rose-700 text-lg mr-1"
                      />
                      {{ $t("unpublished") }}
                    </span>
                  </span>
                  <span
                    v-else-if="h.type == 'tag'"
                    :class="`px-2 text-bold rounded-full text-${
                      h[d[h.key]]
                    }-100 bg-${h[d[h.key]]}-500`"
                  >
                    {{ getVal(h.key, d) }}
                    <span v-if="h.unit">{{ h.unit }}</span>
                  </span>
                  <div class="flex items-center" v-else-if="h.keys">
                    <span v-for="k in h.keys" :key="`keys-${k.key}`">
                      <img
                        v-if="k.type == 'imageOrInitials' && d[k.key]"
                        :src="d[k.key]"
                        class="mr-1 rounded h-7 w-7 object-cover"
                      />
                      <div
                        v-else-if="getVal(k.initialKey, d) != ''"
                        class="mr-1 uppercase rounded h-7 w-7 object-cover bg-gradient-to-tr from-blueGray-400 to-blueGray-600 text-blueGray-50 font-bold flex items-center justify-center"
                      >
                        {{ getInitials(getVal(k.initialKey, d)) }}
                      </div>
                      <template v-else>
                        {{ getVal(k.key, d) }}
                      </template>
                    </span>
                  </div>
                  <span
                    v-else-if="h.type == 'percentTag'"
                    :class="`px-2 text-bold rounded-full text-${getPercentTagColor(
                      h.key,
                      d
                    )}-100 bg-${getPercentTagColor(h.key, d)}-500`"
                  >
                    {{ getVal(h.key, d) }}
                    <span v-if="h.unit">{{ h.unit }}</span>
                  </span>
                  <span v-else-if="h.type == 'hidden'">
                    <!-- show nothing -->
                  </span>
                  <router-link
                    v-else-if="h.type == 'link' && h.key == 'index'"
                    :to="{
                      name: h.linkInfo.name,
                      params: {
                        [h.linkInfo.routeKey]: getVal(h.linkInfo.varKey, d)
                      }
                    }"
                  >
                    {{ index + 1 }}
                  </router-link>
                  <span
                    class="cursor-pointer"
                    v-else-if="h.type == 'link'"
                    @click="pushLink(h, d, $event)"
                  >
                    <span v-if="h.staticName">
                      <i v-if="h.icon" :class="h.icon" /> {{ $t(h.staticName) }}
                    </span>
                    <span v-else>
                      {{ getVal(h.key, d) }}
                    </span>
                    <span v-if="h.unit">{{ h.unit }}</span>
                  </span>
                  <span
                    class="cursor-pointer"
                    v-else-if="h.type == 'export'"
                    @click="$event => h.export(getVal(h.key, d), $event)"
                  >
                    <span v-if="h.staticName">
                      <i v-if="h.icon" :class="h.icon" /> {{ $t(h.staticName) }}
                    </span>
                    <span v-else>
                      {{ getVal(h.key, d) }}
                    </span>
                    <span v-if="h.unit">{{ h.unit }}</span>
                  </span>
                  <span v-else-if="h.type == 'modal'" @click="h.modalClick(d)">
                    <span v-if="!getVal(h.key, d)"> - </span>
                    <span v-else-if="h.rowDisplayName">
                      {{ $t(h.rowDisplayName) }}
                    </span>
                    <span v-else>
                      {{ getVal(h.key, d) }}
                    </span>
                  </span>
                  <span v-else-if="h.type == 'tickets'">
                    <router-link
                      class="border mr-2 px-2 py-1 font-semibold"
                      v-for="ticket in getVal(h.key, d)"
                      :key="ticket.id + 'tickets' + index"
                      :to="{
                        name: 'Ticket-Detail',
                        params: {
                          id: ticket.id
                        }
                      }"
                      :class="{
                        'border-rose-800 text-rose-800': ticket.is_closed,
                        'border-green-800 btext-green-800': !ticket.is_closed
                      }"
                    >
                      {{ ticket.subject }}
                    </router-link>
                    <!-- <span
                      class="border mr-2 px-2 py-1 font-semibold"
                      v-for="ticket in getVal(h.key, d)"
                      :key="ticket.id + 'tickets' + index"
                      :class="{
                        'border-rose-800 bg-rose-50 text-rose-800':
                          ticket.is_closed,
                        'border-green-800 bg-green-50 text-green-800': !ticket.is_closed
                      }"
                    >
                      {{ ticket.subject }}
                    </span> -->
                  </span>
                  <span v-else-if="h.type == 'damages'">
                    <span
                      class="border mr-2 px-2 py-1 font-semibold bg-white"
                      v-for="damage in getVal(h.key, d)"
                      :key="damage.id + 'damages' + index"
                    >
                      <span v-if="damage.type">
                        {{ damage.type.name }}
                      </span>
                      <template v-if="damage.types">
                        <span
                          v-for="({ type, type2 }, dtypeIdx) in damage.types"
                          :key="dtypeIdx + type.name"
                        >
                          <span v-if="dtypeIdx !== 0"> , </span>
                          {{ type.name }}
                          <span v-if="type2">
                            {{ type2.name }}
                          </span>
                        </span>
                      </template>
                    </span>
                  </span>
                  <!-- <router-link
                  v-else-if="h.type == 'link'"
                  :to="{
                    name: h.linkInfo.name,
                    params: {
                      [h.linkInfo.routeKey]: getVal(h.linkInfo.varKey, d)
                    }
                  }"
                >
                  {{ getVal(h.key, d) }}
                  <span v-if="h.unit">{{ h.unit }}</span>
                </router-link> -->
                  <router-link
                    class="text-xl text-right"
                    v-else-if="h.type == 'viewLink'"
                    :to="{
                      name: h.linkInfo.name,
                      params: {
                        [h.linkInfo.routeKey]: getVal(h.linkInfo.varKey, d)
                      }
                    }"
                  >
                    <i class="fas fa-arrow-circle-right"></i>
                  </router-link>
                  <span v-else-if="h.type == 'time'">
                    {{ getTimeFormat(getVal(h.key, d)) }}
                  </span>
                  <label-display
                    v-else-if="h.type == 'label'"
                    :label="d[h.key]"
                  />
                  <template v-else-if="h.type == 'labels'">
                    <label-display
                      v-for="label in d[h.key]"
                      :key="label.id"
                      :label="label"
                    />
                  </template>
                  <template v-else-if="h.type == 'hubs'">
                    <span
                      class="border mr-1 px-2 py-1 border-gray-400"
                      v-for="hub in d[h.key]"
                      :key="hub.id"
                    >
                      {{ hub.name }}
                    </span>
                  </template>
                  <span v-else-if="h.type == 'dateTime'">
                    {{ formatTime(getVal(h.key, d)) }}
                  </span>
                  <div class="w-full" v-else-if="h.type == 'dueDate'">
                    <ListTableDueDate
                      :date="getVal(h.key, d)"
                      :inserted_at="d.inserted_at"
                    />
                  </div>
                  <span v-else-if="h.type == 'address'">
                    {{ getAddress(getVal(h.key, d)) }}
                  </span>
                  <span v-else-if="h.type == 'has_icon'">
                    <i
                      class="mr-2 text-base text-blueGray-300"
                      :class="h[d[h.key]]"
                    />
                    {{ getVal(h.key, d) }}
                    <span v-if="h.unit">{{ h.unit }}</span>
                  </span>
                  <span v-else-if="h.icon">
                    <i class="mr-2 text-base text-green-500" :class="h.icon" />
                    {{ getVal(h.key, d) }}
                    <span v-if="h.unit">{{ h.unit }}</span>
                  </span>
                  <span v-else>
                    <!-- <input
                      v-model="d[h.key]"
                      class="w-full focus:outline-none border-b px-1"
                      style="background: transparent"
                    /> -->
                    {{ getVal(h.key, d) }}
                    <span v-if="h.unit">{{ h.unit }}</span>
                  </span>

                  <span
                    class="mt-2 flex items-center"
                    v-if="h.canEdit === true"
                    @click="$event => h.action($event, d, hidx)"
                  >
                    <MSelect
                      @applySelection="h.applySelection"
                      @cancelSelection="h.cancelSelection"
                      :preSelected="getVal(h.key, d)"
                      :placeholder="$t('select type(s)')"
                      :options="h.items"
                    />
                    <!-- <i class="fas fa-edit ml-2"></i> {{ $t("edit") }} -->
                  </span>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <!-- quick hack to get tailwind to pick up classes need to remove in future -->
        <span
          class="hidden px-2 text-bold rounded-full text-green-100 bg-green-500"
        />
        <span class="hidden text-yellow-100 bg-yellow-500" />
        <span class="hidden text-red-100 bg-red-500" />
        <span class="hidden text-green-100 bg-green-500" />
      </div>
    </div>
  </div>
</template>

<script>
import formatting from "../mixins/formatting";
import userMixins from "@/mixins/userMixins";
import LabelDisplay from "./LabelDisplay.vue";
import ListTableDueDate from "./ListTableDueDate.vue";
import MSelect from "../components/MSelect.vue";
export default {
  name: "ListTable",
  components: { LabelDisplay, ListTableDueDate, MSelect },
  props: {
    qrType: {
      type: String,
      default: ""
    },
    usePadding: {
      type: Boolean,
      default: true
    },
    fullwidth: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: Number,
      required: false
    },
    tableName: {
      type: String,
      default: ""
    },
    createName: {
      type: String,
      default: ""
    },
    headers: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      required: true
    },
    searchable: {
      type: Boolean,
      default: true
    },
    selectedRows: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [formatting, userMixins],
  data() {
    return {
      showQRs: false,
      searchVal: "",
      searchFocus: false,
      sortKey: "",
      sortDirection: "",
      linkInfo: {},
      filterKey: {}
    };
  },
  computed: {
    sortedTableData() {
      let data = [...this.tableData];
      let filterKey = Object.entries(this.filterKey);
      if (filterKey.length > 0) {
        data = data.filter(item => {
          // filter by key to and conditions instead of or
          const res = filterKey.map(([filter, values]) => {
            let d = this.getVal(filter, item);

            // work aroud for getting is_closed in ticket
            if (filter == "is_closed") {
              if (d == true) d = "closed";
              if (d == false) d = "open";
            }
            // work aroud for labels where its mulitple items
            if (Array.isArray(d)) {
              d = d.map(it => it.name).join(" ");
            }
            return values.includes(d);
          });
          // to make sure all the headers key are true for a and condition
          return res.reduce((accu, r) => {
            accu = accu && r;
            return accu;
          }, true);
        });
      }
      if (this.searchVal) {
        data = data.filter(item => {
          let text = this.headers
            .reduce((accu, { key, notSearchable, type }) => {
              if (notSearchable) {
                return accu;
              }
              let val = item[key];
              if (type == "labels") {
                val = val.map(v => v.name).join(" ");
              }
              if (type == "label") {
                val = val?.name;
              }
              if (key.includes(".")) {
                val = this.getVal(key, item);
              }
              return accu + " " + val;
            }, "")
            .trim()
            .toLowerCase();
          return text.includes(this.searchVal.trim().toLowerCase());
        });
      }
      if (this.sortKey && this.sortDirection) {
        data = data.sort((a, b) => {
          let first = a[this.sortKey];
          let second = b[this.sortKey];
          if (this.sortKey.includes(".")) {
            first = this.getVal(this.sortKey, a);
            second = this.getVal(this.sortKey, b);
          }
          try {
            first = first.toLowerCase();
            second = second.toLowerCase();
          } catch {
            //
          }
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });

        data = this.sortDirection == "ASC" ? data : data.reverse();
      }
      return data;
    }
  },
  methods: {
    // printQRs() {
    //   let w = window.open();
    //   w.document.write(document.getElementById("qrs").innerHTML);
    //   w.print();
    //   w.close();
    // },
    toggleFilterKey({ key }, value) {
      if (!(key in this.filterKey)) {
        this.$set(this.filterKey, key, []);
      }
      const foundIndex = this.filterKey[key].findIndex(item => item == value);
      if (foundIndex == -1) {
        this.filterKey[key].push(value);
      } else {
        this.filterKey[key].splice(foundIndex, 1);
      }
      if (this.filterKey[key].length == 0) {
        this.$delete(this.filterKey, key);
      }
      const query = Object.entries(this.filterKey).reduce(
        (accu, [key, values]) => {
          //     key = encodeURIComponent(key);
          // value = encodeURIComponent(value);
          key = encodeURIComponent(key);
          values = encodeURIComponent(values.join("&"));
          accu[key] = values;
          return accu;
        },
        {}
      );
      // Object.entries(query).forEach(([key, value]) => {
      //   this.$route.query[key] = value;
      //   console.log(this.$route.query);
      // });

      // console.log(qs);
      // console.log(this.filterKey);
      this.$router.replace({
        path: this.$route.path,
        query
      });
    },
    getFilterables({ key, type }) {
      let seen = {};
      if (type == "labels") {
        this.tableData.forEach(item => {
          item[key].forEach(tag => {
            seen[tag.name] = true;
          });
        });
      } else if (type == "is_closed") {
        seen = { closed: true, open: true };
      } else {
        this.tableData.forEach(item => (seen[this.getVal(key, item)] = true));
      }
      return Object.keys(seen).filter(item => item != "");
    },
    getHeight() {
      return this.tableHeight
        ? `max-height: ${this.tableHeight + 100}px; min-height: ${
            this.tableHeight - 100
          }px`
        : "height: calc(100vh - 255px)";
    },
    setSort({ key: sortKey, noSort }) {
      if (noSort) return;

      this.sortDirection =
        this.sortKey == sortKey && this.sortDirection == "ASC" ? "DESC" : "ASC";
      this.sortKey = sortKey;
    },
    pushLink(h, d, evt) {
      evt.stopPropagation();
      this.$router.push({
        name: h.linkInfo.name,
        params: {
          [h.linkInfo.routeKey]: this.getVal(h.linkInfo.varKey, d)
        }
      });
    },

    // setSort(sortKey, direction) {
    //   this.sortKey = sortKey;
    //   this.sortDirection = direction;
    // },
    getVal(key, obj) {
      try {
        return key.split(".").reduce((o, i) => o[i], obj);
      } catch (err) {
        return "";
      }
    },
    getPercentTagColor(key, obj) {
      let num = Number(this.getVal(key, obj));
      if (num <= 50 && num > 10) return "green";
      if (num <= 10) return "green";
      return "green";
    },
    handleRowClick(d) {
      if (this.linkInfo) {
        this.$router.push({
          name: this.linkInfo.name,
          params: {
            [this.linkInfo.routeKey]: this.getVal(this.linkInfo.varKey, d)
          }
        });
      }
    }
  },
  created() {
    // this.filterKey[key].push(value);
    this.filterKey = Object.entries(this.$route.query).reduce(
      (accu, [key, value]) => {
        if (key == "result_id") {
          return accu;
        }
        accu[key] = decodeURIComponent(value).split("&");
        return accu;
      },
      {}
    );
    // easy way to make whole row clickable
    // fix workaround in future
    const header = this.headers.find(
      h =>
        h.key == "id" ||
        h.key == "battery.id" ||
        h.key == "index" ||
        h.key == "number"
    );
    if (header) this.linkInfo = header.linkInfo;
    // const closed = this.headers.find(h => h.type == "is_closed");
    // if (closed) {
    //   this.$set(this.filterKey, "open", closed);
    // }
  }
};
</script>
