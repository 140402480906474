import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError } from "./helpers";
import router from "../router";

const getDefaultState = () => {
  return {
    user: {},
    loading: false,
    error: null
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    USER_RESET(state) {
      Object.assign(state, getDefaultState());
    },
    GET_USER_REQUEST(state) {
      state.loading = true;
      state.error = null;
      state.user = [];
    },
    GET_USER_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.user = data;
    },
    GET_USER_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.user = null;
    },
    UPDATE_USER_REQUEST(state) {
      state.loading = true;
      state.error = null;
      // state.user = [];
    },
    UPDATE_USER_SUCCESS(state) {
      state.loading = false;
      state.error = null;
      // state.user = data;
    },
    UPDATE_USER_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      // state.user = null;
    }
  },
  actions: {
    getUser: async ({ commit }, id) => {
      try {
        commit("GET_USER_REQUEST");
        const { data } = await axios.get(`/users/${id}`);
        commit("GET_USER_SUCCESS", data);
      } catch (err) {
        commit("GET_USER_FAILURE", err);
        showError(err);
      }
    },
    toggleUserStatus: async ({ commit, dispatch }, id) => {
      try {
        commit("GET_USER_REQUEST");
        const { data } = await axios.post(`/users/${id}/activate`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The user has been updated!"),
          type: "success"
        });
        commit("UPDATE_USER_SUCCESS", data);
        dispatch("getUser", id);
      } catch (err) {
        commit("GET_USER_FAILURE", err);
        showError(err);
      }
    },
    updateUser: async ({ commit, dispatch }, user) => {
      const { id } = user;
      try {
        commit("UPDATE_USER_REQUEST");
        const { data } = await axios.put(`/users/${id}`, { user });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The user has been updated!"),
          type: "success"
        });
        commit("UPDATE_USER_SUCCESS", data);
        dispatch("getUser", id);
      } catch (err) {
        commit("UPDATE_USER_FAILURE", err);
        showError(err);
      }
    },
    adminPasswordReset: async ({ commit, dispatch }, user) => {
      const { id } = user;
      try {
        commit("UPDATE_USER_REQUEST");
        const { data } = await axios.put(`/users/${id}`, { user });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The user has been updated!"),
          type: "success"
        });
        commit("UPDATE_USER_SUCCESS", data);
        dispatch("getUser", id);
      } catch (err) {
        commit("UPDATE_USER_FAILURE", err);
        showError(err);
      }
    },
    createUser: async ({ dispatch }, user) => {
      try {
        const { data } = await axios.post(`/users`, { user });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The user has been created!"),
          type: "success"
        });
        dispatch("getUser", data.id);
        router.push({
          name: "User-Detail",
          params: {
            id: data.id
          }
        });
      } catch (err) {
        showError(err);
      }
    }
  }
};
