<template>
  <div class="h-full">
    <h2 class="text-blueGray-400 uppercase font-semibold text-xs">
      {{ $t("BREAKAGE MAP") }}
    </h2>
    <div class="flex justify-center items-center h-full flex-grow">
      <i v-if="loading" class="fas fa-sync fa-spin" />
      <apexchart
        v-else-if="damages && damages.length > 0"
        :key="damages.length + 'damage-chart3'"
        height="350"
        type="treemap"
        :options="chart.chartOptions"
        :series="chart.series"
      />
      <h2 v-else class="font-semibold text-lg">
        🙌 {{ $t("nice, no damages") }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssetBreakageMap",
  props: {
    loading: {
      type: Boolean,
      defualt: false
    },
    damages: {
      type: Array,
      require: true
    }
  },
  computed: {
    chart() {
      if (this.damages.length == 0) {
        return null;
      }
      const flattenDamages = this.damages.reduce((accu, damage) => {
        if (damage?.types) {
          accu = accu.concat(damage.types);
        }
        if (damage?.type) {
          accu = accu.concat([damage.type]);
        }
        return accu;
      }, []);
      const damageTally = flattenDamages.reduce((accu, damage) => {
        const statusName = damage?.type?.name ? damage?.type?.name : "No type";
        accu[statusName] = statusName in accu ? accu[statusName] + 1 : 1;
        return accu;
      }, {});
      const data = Object.entries(damageTally).map(([x, y]) => ({ x, y }));

      return {
        series: [{ data }],
        chartOptions: {
          legend: {
            show: false
          },
          chart: {
            height: 350,
            type: "treemap",
            fontFamily: "Montserrat, Arial, sans-serif",
            toolbar: {
              show: false
            }
          }
        }
      };
    }
  }
};
</script>
