import axios from "../axios";

export default {
  namespaced: true,
  state: {
    shifts: {},
    loading: false,
    error: null
  },
  mutations: {
    GET_SHIFTS_REQUEST(state) {
      state.loading = true;
      state.error = null;
      state.shifts = {};
    },
    GET_SHIFTS_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.shifts = data;
    },
    GET_SHIFTS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.shifts = {};
    }
  },
  actions: {
    getAllShifts: async ({ commit }) => {
      try {
        commit("GET_SHIFTS_REQUEST");
        const { data } = await axios.get(`/shifts`);
        commit("GET_SHIFTS_SUCCESS", data);
      } catch (err) {
        commit("GET_SHIFTS_FAILURE", err);
      }
    }
  }
};
