var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded-full flex items-center justify-center",class:{
    'h-5 w-5': _vm.isSmall,
    'h-11 w-11': !_vm.isSmall,
    'bg-blue-100 text-gray-500': _vm.bgNonWhite,
    'bg-white text-gray-400': !_vm.bgNonWhite
  }},[(_vm.user && _vm.user.profile_image)?_c('img',{staticClass:"rounded-full object-cover",class:{
      'h-5 w-5': _vm.isSmall,
      'h-9 w-9': !_vm.isSmall
    },attrs:{"src":_vm.user.profile_image}}):(_vm.is_external)?_c('div',{staticClass:"absolute bg-yellow-500 text-yellow-50 px-1 text-sm"},[_vm._v(" "+_vm._s(_vm.$t("external"))+" ")]):(_vm.user.name)?_c('div',{staticClass:"uppercase font-bold flex items-center justify-center",class:{
      'text-sm': _vm.isSmall,
      'text-lg': !_vm.isSmall
    }},[_vm._v(" "+_vm._s(_vm.getInitials(_vm.user.name))+" ")]):_c('i',{staticClass:"far fa-user-circle",class:{
      'text-sm': _vm.isSmall,
      'text-3xl': !_vm.isSmall
    }})])}
var staticRenderFns = []

export { render, staticRenderFns }