import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError, attachFromAndToUrl } from "./helpers";
import router from "../router";

export default {
  namespaced: true,
  state: {
    ticketConfig: {},
    tickets: [],
    ticket: {},
    loading: false,
    error: null
  },
  mutations: {
    GET_TICKETS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_TICKETS_SUCCESS(state, { data }) {
      state.tickets = data;
      state.loading = false;
      state.error = null;
    },
    GET_TICKETS_FAILURE(state, err) {
      state.tickets = [];
      state.loading = false;
      state.error = err;
    },
    GET_TICKET_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_TICKET_SUCCESS(state, { data }) {
      state.ticket = data;
      state.loading = false;
      state.error = null;
    },
    GET_TICKET_FAILURE(state, err) {
      state.ticket = {};
      state.loading = false;
      state.error = err;
    },
    GET_TICKET_SETTING_REQUEST() {
      // state.loading = true;
      // state.error = null;
    },
    GET_TICKET_SETTING_SUCCESS(state, data) {
      state.ticketConfig = data;
    },
    GET_TICKET_SETTING_FAILUARE(state) {
      state.ticketConfig = {};
    }
  },
  actions: {
    getTicketSetting: async ({ commit }, { cb }) => {
      try {
        commit("GET_TICKET_SETTING_REQUEST");
        const { data } = await axios.get("settings/ticket");
        commit("GET_TICKET_SETTING_SUCCESS", data);
        if (cb) cb(data);
      } catch (err) {
        // showError(err);
        commit("GET_TICKET_SETTING_FAILUARE", err);
      }
    },
    getTickets: ({ rootState, dispatch }) => {
      const url = attachFromAndToUrl(
        `/tickets`,
        rootState.dateRange,
        rootState.selectedHubs
      );
      dispatch("handleGetTickets", url);
    },
    getAllTickets: ({ dispatch }) => {
      dispatch("handleGetTickets", `/tickets`);
    },
    handleGetTickets: async ({ commit }, url) => {
      try {
        commit("GET_TICKETS_REQUEST");
        const { data } = await axios.get(url);
        commit("GET_TICKETS_SUCCESS", data);
      } catch (err) {
        showError(err);
        commit("GET_TICKETS_FAILUARE", err);
      }
    },
    getTicket: async ({ commit }, id) => {
      try {
        commit("GET_TICKET_REQUEST");
        const { data } = await axios.get(`tickets/${id}`);
        commit("GET_TICKET_SUCCESS", data);
      } catch (err) {
        showError(err);
        commit("GET_TICKET_FAILUARE", err);
      }
    },
    getTicketWithQR: async ({ commit }, { id, cb }) => {
      try {
        commit("GET_TICKET_REQUEST");
        const { data } = await axios.get(`tickets/${id}`);
        commit("GET_TICKET_SUCCESS", data);
        if (cb) cb(data);
      } catch (err) {
        showError(err);
        commit("GET_TICKET_FAILUARE", err);
      }
    },
    createTicket: async ({ dispatch }, ticket) => {
      try {
        const { data } = await axios.post(`tickets`, { ticket });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your ticket was successfully created"),
          type: "success"
        });
        router.push({
          name: "Ticket-Detail",
          params: {
            id: data.data.id
          }
        });
        dispatch("getTicket", data.data.id);
      } catch (err) {
        showError(err);
      }
    },
    commentTicket: async ({ dispatch }, message) => {
      try {
        const { data } = await axios.post(`messages`, { message });
        dispatch("getTicket", data.ticket_id);
        return true;
      } catch (err) {
        showError(err);
        return false;
      }
    },
    updateComment: async ({ dispatch }, message) => {
      try {
        const { data } = await axios.patch(`messages/${message.id}`, {
          message
        });
        dispatch("getTicket", data.ticket_id);
        return true;
      } catch (err) {
        showError(err);
        dispatch("getTicket", message.ticket_id);
        return false;
      }
    },
    updateTicket: async ({ dispatch }, { id, ticket }) => {
      try {
        const { data } = await axios.patch(`tickets/${id}`, {
          ticket
        });
        dispatch("getTicket", data.data.id);
      } catch (err) {
        showError(err);
      }
    },
    notifySupplier: async ({ dispatch }, { id, ticket, cb }) => {
      try {
        await axios.patch(`tickets/${id}`, {
          ticket
        });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("ticket has been notified!"),
          type: "success"
        });
        dispatch("getTicket", id);
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    },
    updateTicketTag: async ({ dispatch }, { id, tags }) => {
      try {
        const { data } = await axios.patch(`tickets/${id}`, {
          ticket: {
            tags
          }
        });
        dispatch("getTicket", data.data.id);
      } catch (err) {
        showError(err);
      }
    },
    updateTicketUser: async ({ dispatch }, { id, internal_recipients }) => {
      try {
        const { data } = await axios.patch(`tickets/${id}`, {
          ticket: {
            internal_recipients
          }
        });
        dispatch("getTicket", data.data.id);
      } catch (err) {
        showError(err);
      }
    },
    closeTicket: async ({ dispatch }, { id, is_closed }) => {
      try {
        const { data } = await axios.patch(`tickets/${id}`, {
          ticket: {
            is_closed
          }
        });
        dispatch("getTicket", data.data.id);
        return true;
      } catch (err) {
        showError(err);
        return false;
      }
    },
    deleteTicket: async ({ dispatch }, { ticket, cb }) => {
      try {
        await axios.delete(`/tickets/${ticket.id}`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("ticket has been delete!"),
          type: "success"
        });
        router.push({ name: "Ticket" });
        dispatch("getTickets");
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    }
  }
};
