<template>
  <div
    class="fixed mb-10 md:mb-16 md:top-0 md:pb-0 z-30 w-full left-0 transition-all duration-150"
    :class="{
      'pb-10 bg-transparent': $route.name == 'Map',
      'bg-gray-600': $route.name != 'Map'
    }"
  >
    <div
      class="relative flex flex-wrap items-center justify-between px-8 py-5 min-w-0 break-words w-full shadow-lg bg-white"
      :class="{
        'pl-72': showSideNav,
        'pl-20': !showSideNav
      }"
    >
      <div class="flex items-center">
        <i
          v-if="$route.name != 'Home'"
          class="fas fa-angle-double-left cursor-pointer mr-3"
          @click="lastPage"
        />
        <!-- <i
          v-if="$route.name != 'Home'"
          class="fas fa-arrow-left cursor-pointer mr-3"
          @click="lastPage"
        /> -->
        <!-- <i
          class="fas mr-5 cursor-pointer hidden md:block"
          @click="$store.commit('accounts/TOGGLE_SIDE_NAV')"
          :class="{
            'fa-angle-double-left': $store.state.accounts.user.info.showSideNav,
            'fa-angle-double-right': !$store.state.accounts.user.info.showSideNav
          }"
        /> -->
        <ol class="list-reset flex text-gray-500">
          <li
            v-for="({ label, name }, index) in breadcrumbs"
            :key="`${name}-name`"
            class="truncate uppercase"
          >
            <span class="mx-2" v-if="index != 0">/</span>
            <router-link class="text-blue font-bold uppercase" :to="{ name }">
              {{ $t(label) }}
            </router-link>
          </li>
        </ol>
      </div>
      <AccountSettingDropdown class="hidden md:flex" />
    </div>
  </div>
</template>

<script>
import userMixins from "@/mixins/userMixins";
import AccountSettingDropdown from "./AccountSettingDropdown.vue";
export default {
  name: "SubNav",
  components: {
    AccountSettingDropdown
  },
  mixins: [userMixins],
  computed: {
    showSideNav() {
      return this.$store.state?.accounts?.user?.info?.showSideNav;
    },
    routeName() {
      return this.$route?.name ? this.$route.name : "";
    },
    breadcrumbs() {
      let routes = [{ label: "dashboard", name: "Home" }];
      let details = this.routeName.split("-");
      if (details.length > 1) {
        routes.push({
          name: details[0],
          label:
            details[0].toLowerCase() == "battery"
              ? "batteries"
              : `${details[0].toLowerCase()}s`
        });
      }
      if (this.routeName != "Home") {
        routes.push({
          name: this.routeName,
          label:
            this.routeName.toLowerCase().replace("-", " ") == "battery"
              ? "batteries"
              : `${this.routeName.toLowerCase().replace("-", " ")}s`
        });
      }
      return routes;
    }
  },
  methods: {
    lastPage() {
      // const name =
      //   this.lastPageName != ""
      //     ? this.lastPageName
      //     : this.$route.name.replace("-Detail", "");
      // this.$router.push({ name });
      this.$router.back();
    }
  }
};
</script>
