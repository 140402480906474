import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError } from "./helpers";

export default {
  namespaced: true,
  state: {
    updatePasswordloading: false,
    updatePasswordError: null
  },
  mutations: {
    UPDATE_PASSWORD_REQUEST(state) {
      state.updatePasswordloading = true;
      state.updatePasswordError = null;
    },
    UPDATE_PASSWORD_SUCCESS(state) {
      state.updatePasswordloading = false;
      state.updatePasswordError = null;
    },
    UPDATE_PASSWORD_FAILURE(state, error) {
      state.updatePasswordloading = false;
      state.updatePasswordError = error;
    }
  },
  actions: {
    async uploadProfileImage({ dispatch }, formData) {
      try {
        await axios.post(`/uploads`, formData);
        dispatch("accounts/me", {}, { root: true });
      } catch (err) {
        // commit("LOGIN_FAILURE", err);
      }
    },
    async updateUserInfo({ dispatch }, user) {
      try {
        await axios.put(`/users/update-me`, {
          action: "update_user_info",
          user
        });
        dispatch("accounts/me", {}, { root: true });
        // crazy syntax to workaround the general form design
        let notify =
          user?.notify != null || user?.notify != undefined
            ? user?.notify
            : true;
        if (notify) {
          Notification({
            title: i18n.t("Success"),
            message: i18n.t("Your user profile has been updated!"),
            type: "success"
          });
        }
      } catch (err) {
        showError(err);
      }
    },
    async enableApiToken({ dispatch }) {
      try {
        await axios.put(`/users/update-me`, {
          action: "enable_token_api"
        });
        dispatch("accounts/me", {}, { root: true });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your developers account has been activated!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    },
    async disableApiToken({ dispatch, commit }) {
      try {
        await axios.put(`/users/update-me`, {
          action: "disable_token_api"
        });
        commit("accounts/DELETE_API_TOKEN", {}, { root: true });
        dispatch("accounts/me", {}, { root: true });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your developers account has been deactivated!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    },
    async removeProfileImage({ dispatch }) {
      try {
        await axios.put(`/users/update-me`, {
          action: "remove_profile_image"
        });
        dispatch("accounts/me", {}, { root: true });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your user profile has been updated!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    },
    async changePassword(
      { commit },
      { current_password, password, password_confirmation }
    ) {
      try {
        commit("UPDATE_PASSWORD_REQUEST");
        const { data } = await axios.put(`/users/update-me `, {
          action: "update_password",
          current_password,
          user: {
            password,
            password_confirmation
          }
        });
        commit("UPDATE_PASSWORD_SUCCESS", data);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your password has been updated!"),
          type: "success"
        });
      } catch (err) {
        commit("UPDATE_PASSWORD_FAILURE", err);
        showError(err);
      }
    }
  }
};
