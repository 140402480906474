import axios from "../axios";
import { attachHubUrl, mapObjectsById } from "./helpers";

export default {
  namespaced: true,
  state: {
    bikes: [],
    loading: false,
    error: null
  },
  getters: {
    bikesByID(state) {
      return mapObjectsById(state.bikes);
    }
  },
  mutations: {
    GET_BIKES_REQUEST(state) {
      state.loading = true;
      state.error = null;
      state.bikes = [];
    },
    GET_BIKES_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.bikes = data;
    },
    GET_BIKES_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bikes = [];
    }
  },
  actions: {
    getAllBikes: async ({ commit, rootState }, options) => {
      const noFilter = options?.noFilter;
      const cb = options?.cb;
      const url = noFilter
        ? `/bikes`
        : attachHubUrl(`/bikes`, rootState.selectedHubs);
      try {
        commit("GET_BIKES_REQUEST");
        const { data } = await axios.get(url);
        commit("GET_BIKES_SUCCESS", data);
        if (cb) cb();
      } catch (err) {
        commit("GET_BIKES_FAILURE", err);
      }
    },
    getAllBookableAssets: async ({ commit }) => {
      try {
        commit("GET_BIKES_REQUEST");
        const { data } = await axios.get(`/bikes?only_bookable=true`);
        commit("GET_BIKES_SUCCESS", data);
      } catch (err) {
        commit("GET_BIKES_FAILURE", err);
      }
    }
  }
};
