import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocale from "element-ui/lib/locale/lang/en";
import deLocale from "element-ui/lib/locale/lang/de";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

let messages = loadLocaleMessages();

messages = {
  en: {
    ...messages.en,
    ...enLocale
  },
  de: {
    ...messages.de,
    ...deLocale
  }
};

let locale = process.env.VUE_APP_I18N_LOCALE || "en";

try {
  let navLang = navigator?.userLanguage;
  navLang = window?.navigator?.language;

  if (navLang.includes("de")) {
    locale = "de";
  }

  let user = JSON.parse(localStorage.getItem("vault-dashboard"));
  if (user?.info?.lang) {
    locale = user?.info?.lang;
  }
} catch (err) {
  //
}

export default new VueI18n({
  locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages,
  silentTranslationWarn: true
});
