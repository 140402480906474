import axios from "../axios";
import { MessageBox } from "element-ui";
import i18n from "../i18n";
import { showError } from "./helpers";

export default {
  namespaced: true,
  state: {
    bike_damage_tags: [],
    ticket_tags: [],
    bike_status_labels: [],
    loading: false,
    error: null
  },
  mutations: {
    RESET_ERROR(state) {
      state.error = null;
    },
    GET_BIKE_DAMAGES_TAG_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_BIKE_DAMAGES_TAG_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.bike_damage_tags = data;
    },
    GET_BIKE_DAMAGES_TAG_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bike_damage_tags = [];
    },
    GET_TICKET_TAG_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_TICKET_TAG_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.ticket_tags = data;
    },
    GET_TICKET_TAG_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.ticket_tags = [];
    },
    GET_BIKE_STATUS_LABELS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_BIKE_STATUS_LABELS_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.bike_status_labels = data;
    },
    GET_BIKE_STATUS_LABELS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bike_status_labels = [];
    },
    ERROR_STATUS(state, error) {
      state.error = Object.entries(error).reduce((accu, [key, value]) => {
        accu = accu + `${key} ${value}`;
        return accu;
      }, "");
    }
  },
  actions: {
    getAllTags({ dispatch }) {
      dispatch("getAllBikeDamageTags");
      dispatch("getAllBikeStatusLabels");
      dispatch("getAllTicketTags");
    },
    getAllBikeDamageTags: async ({ commit }) => {
      try {
        commit("GET_BIKE_DAMAGES_TAG_REQUEST");
        const { data } = await axios.get(`/tags/bike-damages`);
        commit("GET_BIKE_DAMAGES_TAG_SUCCESS", data);
      } catch (err) {
        commit("GET_BIKE_DAMAGES_TAG_FAILURE", err);
      }
    },
    getAllTicketTags: async ({ commit }) => {
      try {
        commit("GET_TICKET_TAG_REQUEST");
        const { data } = await axios.get(`/tags/tickets`);
        commit("GET_TICKET_TAG_SUCCESS", data);
      } catch (err) {
        commit("GET_TICKET_TAG_FAILURE", err);
      }
    },
    getAllBikeStatusLabels: async ({ commit }) => {
      try {
        commit("GET_BIKE_STATUS_LABELS_REQUEST");
        const { data } = await axios.get(`/tags/bike-status`);
        commit("GET_BIKE_STATUS_LABELS_SUCCESS", data);
      } catch (err) {
        commit("GET_BIKE_STATUS_LABELS_FAILURE", err);
      }
    },
    updateBikeDamageTag: async ({ dispatch, commit }, tag) => {
      try {
        await axios.patch(`/tags/${tag.id}`, { tag });
        dispatch("getAllTags");
        return true;
      } catch (err) {
        showError(err);
        commit("ERROR_STATUS", err.response.data.errors);
        return false;
      }
    },
    upsertBikeDamageTag: async ({ dispatch, commit }, tag) => {
      try {
        await axios.post(`/tags`, { tag });
        dispatch("getAllTags");
        return true;
      } catch (err) {
        showError(err);
        commit("ERROR_STATUS", err.response.data.errors);
        return false;
      }
    },
    deleteLabel: ({ dispatch }, { id, label }) => {
      MessageBox.confirm(
        i18n.t(`Are you sure you want to delete the label?`) + label,
        "Warning",
        {
          confirmButtonText: i18n.t("OK"),
          cancelButtonText: i18n.t("Cancel"),
          type: i18n.t("warning")
        }
      ).then(async () => {
        try {
          await axios.delete(`/tags/${id}`);
          dispatch("getAllTags");
        } catch (err) {
          showError(err);
        }
      });
    }
  }
};
