import axios from "../axios";
import router from "../router";
import { Notification } from "element-ui";
import i18n from "../i18n";

const getDefaultState = () => {
  return {
    loading: false,
    error: null,
    api_token: null,
    user: {
      id: null,
      name: "",
      email: "",
      token: "",
      account_type: "",
      // showSideNav: false,
      info: {
        showSideNav: false,
        language: "en"
      },
      ...JSON.parse(localStorage.getItem("vault-dashboard"))
    }
  };
};
const state = getDefaultState();

export default {
  namespaced: true,
  state,
  getters: {
    isAuthenticated(state) {
      return state.user.token;
    }
  },
  mutations: {
    TOGGLE_SIDE_NAV(state) {
      // state.user.showSideNav = !state.user.showSideNav;
      state.user.info.showSideNav = !state.user.info.showSideNav;
      localStorage.setItem("vault-dashboard", JSON.stringify(state.user));
    },
    LOGIN_REQUEST(state) {
      localStorage.removeItem("vault-dashboard");
      Object.assign(state, getDefaultState());
      state.loading = true;
    },
    DELETE_API_TOKEN(state) {
      state.api_token = null;
    },
    LOGIN_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.user = data;

      if (data?.api_token) {
        state.api_token = data?.api_token;
        delete data.api_token;
      }
      localStorage.setItem("vault-dashboard", JSON.stringify(data));
    },
    ME_SUCCESS(state, data) {
      //remove once google approves
      if (
        data.email.includes("withdropp.com") ||
        data.email.includes("getvault.app")
      ) {
        localStorage.setItem("vault-withdropp", true);
      }

      state.user = {
        ...state.user,
        ...data
      };

      if (data?.api_token) {
        state.api_token = data?.api_token;
        delete state.user.api_token;
      }
      if (data?.info?.lang) {
        i18n.locale = data?.info?.lang;
      }
      localStorage.setItem("vault-dashboard", JSON.stringify(state.user));
    },
    LOGIN_FAILURE(state, error) {
      localStorage.removeItem("vault-dashboard");
      Object.assign(state, getDefaultState());
      state.error = error;
    },
    LOGOUT(state) {
      localStorage.removeItem("vault-dashboard");
      Object.assign(state, getDefaultState());
    }
    // SET_ITEM_LEVEL: (state, data) => (state.itemLevel = data)
  },
  actions: {
    toggleSideNav({ dispatch, state, commit }) {
      commit("TOGGLE_SIDE_NAV");
      dispatch(
        "updateUserInfo/updateUserInfo",
        {
          notify: false,
          info: {
            ...state.user.info,
            showSideNav: state.user.info.showSideNav
          }
        },
        {
          root: true
        }
      );
    },
    async me({ commit, dispatch }) {
      try {
        const { data } = await axios.get(`/users/me`);
        commit("ME_SUCCESS", data);
        document.title = `Vault x ${data?.company?.name || "You"}`;
      } catch (err) {
        if (err.response.data.error == "Please login first") {
          dispatch("logout");
          Notification.error({
            title: i18n.t("Error"),
            message: i18n.t("Please login first")
          });
        }
      }
    },
    async login({ commit, dispatch }, payload) {
      try {
        commit("LOGIN_REQUEST");
        const { data } = await axios.post(`/users/login`, payload);
        commit("LOGIN_SUCCESS", data);
        const next = router.currentRoute?.query?.redirect
          ? { path: router.currentRoute.query.redirect }
          : { name: "Home" };
        router.push(next);
        dispatch("me");
      } catch (err) {
        const message =
          payload?.action != "LOGIN_WITH_GOOGLE"
            ? i18n.t("Please enter a valid username and password")
            : i18n.t("Please make sure your company authorized you as an user");

        Notification.error({
          title: i18n.t("Error"),
          message
        });
        commit("LOGIN_FAILURE", err);
      }
    },
    logout({ commit }, redirectLogin = true) {
      commit("LOGOUT");
      if (redirectLogin) {
        router.push({ name: "Login" });
      }
    }
  }
};
