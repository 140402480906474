import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError, mapObjectsById } from "./helpers";
import router from "../router";

export default {
  namespaced: true,
  state: {
    supplier: {},
    suppliers: [],
    loading: false,
    error: null
  },
  getters: {
    suppliersByID(state) {
      return mapObjectsById(state.suppliers);
    }
  },
  mutations: {
    GET_SUPPLIERS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_SUPPLIERS_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.suppliers = data;
    },
    GET_SUPPLIERS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.suppliers = [];
    },
    GET_SUPPLIER_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_SUPPLIER_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.supplier = data;
    },
    GET_SUPPLIER_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.supplier = {};
    }
  },
  actions: {
    getAllSuppliers: async ({ commit }) => {
      try {
        commit("GET_SUPPLIERS_REQUEST");
        const { data } = await axios.get(`/suppliers`);
        commit("GET_SUPPLIERS_SUCCESS", data);
      } catch (err) {
        commit("GET_SUPPLIERS_FAILURE", err);
      }
    },
    getSupplier: async ({ commit }, id) => {
      try {
        commit("GET_SUPPLIER_REQUEST");
        const { data } = await axios.get(`/suppliers/${id}`);
        commit("GET_SUPPLIER_SUCCESS", data);
      } catch (err) {
        commit("GET_SUPPLIER_FAILURE", err);
      }
    },
    updateSupplier: async ({ dispatch }, supplier) => {
      const { id } = supplier;
      try {
        await axios.patch(`/suppliers/${id}`, {
          supplier
        });
        dispatch("getSupplier", id);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The supplier has been updated!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    },
    createSupplier: async ({ dispatch }, supplier) => {
      try {
        const { data } = await axios.post(`/suppliers`, { supplier });
        dispatch("getSupplier", data.id);
        router.push({
          name: "Supplier-Detail",
          params: {
            id: data.id
          }
        });
      } catch (err) {
        showError(err);
      }
    },
    deleteSupplier: async ({ dispatch }, { supplier, cb }) => {
      try {
        await axios.delete(`/suppliers/${supplier.id}`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("supplier has been delete!"),
          type: "success"
        });
        router.push({ name: "Supplier" });
        dispatch("getAllSuppliers");
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    }
  }
};
