var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed mb-10 md:mb-16 md:top-0 md:pb-0 z-30 w-full left-0 transition-all duration-150",class:{
    'pb-10 bg-transparent': _vm.$route.name == 'Map',
    'bg-gray-600': _vm.$route.name != 'Map'
  }},[_c('div',{staticClass:"relative flex flex-wrap items-center justify-between px-8 py-5 min-w-0 break-words w-full shadow-lg bg-white",class:{
      'pl-72': _vm.showSideNav,
      'pl-20': !_vm.showSideNav
    }},[_c('div',{staticClass:"flex items-center"},[(_vm.$route.name != 'Home')?_c('i',{staticClass:"fas fa-angle-double-left cursor-pointer mr-3",on:{"click":_vm.lastPage}}):_vm._e(),_c('ol',{staticClass:"list-reset flex text-gray-500"},_vm._l((_vm.breadcrumbs),function(ref,index){
    var label = ref.label;
    var name = ref.name;
return _c('li',{key:(name + "-name"),staticClass:"truncate uppercase"},[(index != 0)?_c('span',{staticClass:"mx-2"},[_vm._v("/")]):_vm._e(),_c('router-link',{staticClass:"text-blue font-bold uppercase",attrs:{"to":{ name: name }}},[_vm._v(" "+_vm._s(_vm.$t(label))+" ")])],1)}),0)]),_c('AccountSettingDropdown',{staticClass:"hidden md:flex"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }