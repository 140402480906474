import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError } from "./helpers";

const getDefaultState = () => {
  return {
    loading: false,
    error: null,
    checkListItem: {},
    checkListItems: []
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    RESET(state) {
      Object.assign(state, getDefaultState());
    },
    BIND_SAVED_DATA(state, { items: responses, checklist_result_id }) {
      const checklist_results_by_id = responses.reduce((accu, item) => {
        accu[item.checklist_item_id] = item;
        return accu;
      }, {});

      state.checkListItems = state.checkListItems.map(item => {
        const response = checklist_results_by_id[item.id];

        if (response?.["checklist_item_id"]) {
          return {
            ...item,
            checklist_result_id,
            checklist_item_id: response["checklist_item_id"],
            datetime: response["datetime"],
            medias: response["medias"],
            multi_value: response["multi_value"],
            pass: response["pass"],
            single_number_value: response["single_number_value"],
            single_value: response["single_value"],
            solutions_rejected: response["solutions_rejected"],
            solutions_selected: response["solutions_selected"],
            checklist_response_id: response["id"]
          };
        }
        return { ...item, checklist_result_id };
      });
    },
    GET_CHECK_LIST_ITEMS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_CHECK_LIST_ITEMS_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.checkListItems = data;
    },
    GET_CHECK_LIST_ITEMS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.checkListItems = [];
    },
    GET_CHECK_LIST_ITEM_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_CHECK_LIST_ITEM_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.checkListItem = data;
    },
    GET_CHECK_LIST_ITEM_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.checkListItem = {};
    }
  },
  actions: {
    // refactor in the future
    getAllCheckListItemsWithcb: async ({ commit }, { id, cb }) => {
      try {
        commit("GET_CHECK_LIST_ITEMS_REQUEST");
        const { data } = await axios.get(`/checklists/${id}/all-items`);
        commit("GET_CHECK_LIST_ITEMS_SUCCESS", data);
        if (cb) cb();
      } catch (err) {
        commit("GET_CHECK_LIST_ITEMS_FAILURE", err);
      }
    },
    getAllCheckListItems: async ({ commit }, id) => {
      try {
        commit("GET_CHECK_LIST_ITEMS_REQUEST");
        const { data } = await axios.get(`/checklists/${id}/all-items`);
        commit("GET_CHECK_LIST_ITEMS_SUCCESS", data);
      } catch (err) {
        commit("GET_CHECK_LIST_ITEMS_FAILURE", err);
      }
    },
    upsertCheckListItems: async (
      { dispatch, commit },
      { check_list_items, check_list_id, cb }
    ) =>
      // { check_list_items, check_list_id, cb }
      {
        commit("progression/SET_PROGRESSION", 5, { root: true });
        try {
          await axios.post(`/checklist-items`, check_list_items, {
            onUploadProgress: progressEvent => {
              commit(
                "progression/SET_PROGRESSION",
                Math.round((progressEvent.loaded * 100) / progressEvent.total),
                { root: true }
              );
            }
          });
          Notification({
            title: i18n.t("Success"),
            message: i18n.t("Checklist has been saved!"),
            type: "success"
          });
          commit("progression/SET_PROGRESSION", 0, { root: true });
          if (cb) cb();
          dispatch("getAllCheckListItems", check_list_id);
        } catch (err) {
          commit("progression/SET_PROGRESSION", 0, { root: true });
          showError(err);
        }
      },
    deleteCheckListItem: async (_, { id }) => {
      try {
        // commit("GET_CHECK_LIST_REQUEST");
        await axios.delete(`/checklist-items/${id}`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Checklist item has been removed!"),
          type: "success"
        });
        // commit("GET_CHECK_LIST_SUCCESS", data);
      } catch (err) {
        // commit("GET_CHECK_LIST_FAILURE", err.response?.data);
        showError(err);
      }
    },
    deleteCheckListMedia: async (
      { dispatch },
      { id, mediaId, checklistId }
    ) => {
      try {
        // commit("GET_CHECK_LIST_REQUEST");
        await axios.delete(`/checklist-items/${id}/media/${mediaId}`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("media has been removed!"),
          type: "success"
        });
        dispatch("getAllCheckListItems", checklistId);
        // commit("GET_CHECK_LIST_SUCCESS", data);
      } catch (err) {
        // commit("GET_CHECK_LIST_FAILURE", err.response?.data);
        showError(err);
      }
    }
    // createCheckList: async ({ dispatch }, check_list) => {
    //   try {
    //     const { data } = await axios.post(`/checklists`, { check_list });
    //     Notification({
    //       title: i18n.t("Success"),
    //       message: i18n.t("Checklist has been added!"),
    //       type: "success"
    //     });
    //     dispatch("getCheckList", { id: data.id });
    //   } catch (err) {
    //     showError(err);
    //   }
    // },
    // updateCheckList: async ({ dispatch }, { check_list, cb }) => {
    //   try {
    //     await axios.put(`/checklists/${check_list.id}`, {
    //       check_list
    //     });
    //     Notification({
    //       title: i18n.t("Success"),
    //       message: i18n.t("Checklist has been updated!"),
    //       type: "success"
    //     });
    //     if (cb) cb();
    //     dispatch("getCheckList", { id: check_list.id });
    //   } catch (err) {
    //     showError(err);
    //   }
    // }
  }
};
