<template>
  <div class="w-full" ref="elpopover">
    <el-popover
      class="relative w-full"
      v-model="visible"
      placement="bottom"
      :disabled="isDisabled"
      :width="width"
      popper-class="pzero-popover"
      @after-enter="handleUpdateItemEnter"
    >
      <template #reference>
        <div
          :style="`height: 40px`"
          class="border-gray-300 border px-2 py-1 flex items-center relative mr-4 overflow-hidden max-w-full w-full"
          :class="{ 'cursor-not-allowed bg-coolGray-100': isDisabled }"
          @click="handleOpen($event)"
        >
          <div class="text-gray-500 text-xs">
            {{ placeholder }}
          </div>
          <!-- <input
            class="focus:ring-0 focus:outline-none px-3 py-1"
            v-model="searchTerm"
            :class="{ 'cursor-not-allowed bg-coolGray-100': isDisabled }"
          /> -->
          <i
            v-if="isOpen"
            class="fas fa-chevron-up text-coolGray-400 text-xs absolute right-2"
          />
          <i
            v-else
            class="fas fa-chevron-down text-coolGray-400 text-xs absolute right-2"
          />
        </div>
      </template>
      <div style="width: 100%">
        <div
          class="bg-white flex justify-between border-b px-4 py-3 font-semibold"
        >
          <span
            class="text-xs text-slateGray hover:text-rocketBlue cursor-pointer"
            @click="clearAll"
          >
            {{ $t("Clear All") }}
          </span>
          <span
            class="text-xs text-slateGray hover:text-rocketBlue cursor-pointer"
            @click="selectAll"
          >
            {{ $t("Select All") }}
          </span>
        </div>
        <div class="p-3 pb-2">
          <input
            class="border px-3 py-2 w-full focus:outline-none"
            :placeholder="$t('search')"
            v-model="searchTerm"
          />
        </div>
        <div class="h-44 overflow-y-auto">
          <div class="px-5 font-semibold" v-if="!filteredOptions.length">
            {{ $t("no results") }}
          </div>
          <div
            class="cursor-pointer flex justify-between px-4 py-3 rounded items-center text-coolGray-500 hover:bg-gray-50 border-t"
            @click="toggleOption(option)"
            v-for="option in filteredOptions"
            :key="`id-${option.id}`"
            :class="{
              'bg-coolGray-100': isSelected(option)
            }"
          >
            <span class="flex"
              ><ProfileImage
                class="mr-1"
                :isSmall="true"
                :user="option"
                :bgNonWhite="true"
                v-if="isUser"
              />{{ option.name }}</span
            >
            <i class="fas fa-check text-xs" v-if="isSelected(option)" />
          </div>
        </div>
        <div class="bg-white border-t px-4 py-3 flex justify-end">
          <button
            class="px-2 py-1 bg-blueGray-400 text-white text-xs hover:bg-blueGray-500 mr-2"
            @click="cancelSelection($event)"
          >
            {{ $t("Cancel") }}
          </button>
          <button
            class="px-2 py-1 bg-blueGray-400 text-white text-xs hover:bg-blueGray-500"
            @click="handleApplySelection"
          >
            {{ $t("Apply") }}
          </button>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import ProfileImage from "./ProfileImage.vue";
export default {
  name: "MSelect",
  components: { ProfileImage },
  props: {
    preSelected: {
      type: Array
    },
    placeholder: {
      type: String
    },
    options: {
      type: Array
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      width: 0,
      hasChanges: false,
      selected: [],
      visible: false,
      searchTerm: "",
      isOpen: false,
      localState: this.preSelected
    };
  },
  watch: {
    preSelected() {
      this.hasChanges = false;
      if (Array.isArray(this.preSelected)) {
        this.selected = [...this.preSelected];
      } else {
        this.selected = [];
      }
    }
  },
  computed: {
    filteredOptions() {
      return this.searchTerm === ""
        ? this.options
        : this.options.filter(
            ({ name }) =>
              name && name.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
    }
  },
  methods: {
    handleUpdateItemEnter() {
      this.searchTerm = "";
    },
    handleOpen(e) {
      e.stopPropagation();
      this.isOpen = true;
    },
    cancelSelection(e) {
      e.stopPropagation();
      this.isOpen = false;
      this.visible = false;
      if (this.hasChanges) {
        this.$emit("cancelSelection");
      }
    },
    handleApplySelection() {
      this.$emit(
        "applySelection",
        this.selected.map(s => s.id)
      );
      this.isOpen = false;
      this.visible = false;
    },
    onOutsideClick() {
      if (this.isOpen && this.hasChanges) {
        this.handleApplySelection();
      }
    },
    deselectOption(option, event) {
      this.hasChanges = true;
      if (event) {
        event.stopPropagation();
      }
      this.selected = this.selected.filter(o => o.id != option.id);
    },
    selectOption(option) {
      this.hasChanges = true;
      if (!this.isSelected(option)) {
        this.selected.push(option);
      }
    },
    toggleOption(option) {
      this.hasChanges = true;
      if (this.isSelected(option)) {
        this.deselectOption(option);
      } else {
        this.selectOption(option);
      }
    },
    isSelected(option) {
      return this.selected.find(o => o.id == option.id);
    },
    clearAll() {
      this.hasChanges = true;
      this.selected = [];
    },
    selectAll() {
      this.hasChanges = true;
      this.selected = [...this.options];
    }
  },
  mounted() {
    document.addEventListener("click", this.onOutsideClick);
    this.width = this.$refs?.elpopover.clientWidth;
  },
  destroyed() {
    document.removeEventListener("click", this.onOutsideClick);
  }
};
</script>

<style>
.pzero-popover {
  padding: 0 !important;
}
</style>
