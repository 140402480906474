var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block transition-all duration-1000"},[_c('nav',{staticClass:"border-r px-3 md:px-0 md:left-0 md:py-0 md:block md:fixed md:top-0 md:bottom-0 md:flex-row md:flex-nowrap md:overflow-hidden py-4 shadow-2xl bg-white flex flex-wrap items-center justify-between fixed w-full top-0 z-50",class:{ 'md:w-14': !_vm.showSideNav, 'md:w-64': _vm.showSideNav }},[_c('div',{staticClass:"flex px-2 items-center justify-center",class:{
        'md:border-b': !_vm.showSideNav
      },staticStyle:{"max-height":"75px"}},[_c('span',{staticClass:"md:hidden",on:{"click":function($event){_vm.drawer = true}}},[_c('i',{staticClass:"fas fa-bars mr-4"})]),_c('router-link',{ref:"logoarea",staticClass:"text-center text-blueGray-600 whitespace-nowrap text-sm font-bold flex justify-center items-center",class:{
          'md:pt-5 md:pb-4': !_vm.showSideNav,
          'md:pr-10 md:pt-9': _vm.showSideNav
        },attrs:{"to":"/"}},[(_vm.logo)?_c('img',{attrs:{"width":"100","src":_vm.logo}}):_c('span',{staticClass:"text-xl text-orange-600"},[_vm._v(_vm._s(_vm.partner))])])],1),_c('AccountSettingDropdown',{staticClass:"md:hidden"}),_c('div',{staticClass:"overflow-auto hidden md:block",class:{ 'md:mt-6': !_vm.showSideNav },style:(("height: calc(100vh - " + _vm.height + "px)"))},_vm._l((_vm.navItems),function(ref,i){
        var items = ref.items;
        var label = ref.label;
return _c('div',{key:label,staticClass:"mb-5",class:{ 'border-blueGray-200 border-t': i != 0 }},[(_vm.showSideNav)?_c('p',{staticClass:"mt-5 mb-1 pt-2 pl-7 font-semibold text-gray-400"},[_vm._v(" "+_vm._s(_vm.$t(label))+" ")]):_vm._e(),_c('ul',{staticClass:"hidden md:flex md:flex-col md:min-w-full flex-col list-none"},_vm._l((items),function(ref){
        var name = ref.name;
        var label = ref.label;
        var icon = ref.icon;
        var space = ref.space;
return _c('li',{key:name,staticClass:"items-center border-l-8 border-white mb-2",class:{
              'border-cyan-500': _vm.$route.name == name,
              'px-3': _vm.showSideNav
            }},[_c('router-link',{attrs:{"to":{ name: name, params: { space: space } }}},[_c('span',{staticClass:"text-sm hover:bg-gray-100 rounded px-3 uppercase py-3 font-bold block text-blueGray-700 transition duration-500 ease-in-out transform hover:text-blueGray-500 hover:translate-x-2",class:{ 'bg-gray-100': _vm.$route.name == name }},[(!_vm.showSideNav)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t(label),"placement":"right"}},[_c('i',{staticClass:"mr-2 text-base text-blueGray-300 w-4",class:icon})]):_vm._e(),(_vm.showSideNav)?[_c('i',{staticClass:"mr-2 text-base text-blueGray-300 w-4",class:icon}),_c('span',[_vm._v(" "+_vm._s(_vm.$t(label))+" ")])]:_vm._e()],2)])],1)}),0)])}),0),_c('div',{ref:"copyright",staticClass:"fixed bottom-0 left-0 px-3 py-2 border-t border-r hidden bg-white md:flex text-center justify-center items-center",class:{ 'md:w-14': !_vm.showSideNav, 'md:w-64': _vm.showSideNav }},[_vm._m(0),(_vm.showSideNav)?_c('p',{staticClass:"text-xs text-blueGray-500 font-semibold flex flex-col text-left ml-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("Copyright © "))+" "+_vm._s(_vm.currentYear)+". ")]),_c('span',{staticClass:"-mt-1"},[_vm._v(_vm._s(_vm.$t("vault solutions.")))])]):_vm._e()])],1),_c('el-drawer',{attrs:{"visible":_vm.drawer,"direction":"ltr","size":"53%"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"absolute top-10 left-6"},[_c('LangSelect')],1),_c('div',{staticClass:"overflow-auto md:block",style:("height: calc(100vh - 82px)")},_vm._l((_vm.navItems),function(ref,i){
            var items = ref.items;
            var label = ref.label;
return _c('div',{key:label,staticClass:"mb-5",class:{ 'border-blueGray-200 border-t': i != 0 }},[_c('p',{staticClass:"mt-5 mb-1 pt-2 pl-7 font-semibold text-gray-400"},[_vm._v(" "+_vm._s(_vm.$t(label))+" ")]),_c('ul',{staticClass:"md:flex md:flex-col md:min-w-full flex-col list-none"},_vm._l((items),function(ref){
            var name = ref.name;
            var label = ref.label;
            var icon = ref.icon;
            var space = ref.space;
return _c('li',{key:name,staticClass:"items-center px-3 border-l-8 border-white mb-2",class:{ 'border-cyan-500': _vm.$route.name == name },on:{"click":function($event){_vm.drawer = false}}},[_c('router-link',{attrs:{"to":{ name: name, params: { space: space } }}},[_c('span',{staticClass:"text-sm hover:bg-gray-100 rounded px-3 uppercase py-3 font-bold block text-blueGray-700 transition duration-500 ease-in-out transform hover:text-blueGray-500 hover:translate-x-2",class:{ 'bg-gray-100': _vm.$route.name == name }},[_c('i',{staticClass:"mr-2 text-base text-blueGray-300 w-4",class:icon}),_vm._v(" "+_vm._s(_vm.$t(label))+" ")])])],1)}),0)])}),0)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"inline",attrs:{"alt":"vault logo","width":"50","src":require("@/assets/vault_transparent_ukraine_flag_logo.png")}})])}]

export { render, staticRenderFns }