<template>
  <div class="home -mt-14">
    <BoxMetrics @getData="getData" />
  </div>
</template>

<script>
import BoxMetrics from "@/components/BoxMetrics.vue";

export default {
  name: "Home",
  components: {
    BoxMetrics
  },
  methods: {
    getData() {
      this.$store.dispatch("bikes/getAllBikes");
      // this.$store.dispatch("batteries/getAllBatteries");
      this.$store.dispatch("damages/getDamages");
      this.$store.dispatch("tickets/getTickets");
      this.$store.dispatch("suppliers/getAllSuppliers");
      this.$store.dispatch("checkouts/getAllCheckouts");
      this.$store.dispatch("checkListResults/getPureCheckListResults");
    }
  },
  created() {
    this.$store.dispatch("hubs/getAllHubs", hubs => {
      this.$store.commit(
        "SET_HUBS",
        hubs.map(({ id }) => id)
      );
      this.getData();
    });
    this.$store.dispatch("typeDefinitions/getAllBikeDefinitions");
    // this.getData();
  }
};
</script>
