import axios from "../axios";
// import { Notification } from "element-ui";
// import i18n from "../i18n";
// import { showError, mapObjectsById } from "./helpers";
// import router from "../router";

export default {
  namespaced: true,
  state: {
    space: {},
    spaces: [],
    loading: false,
    error: null
  },
  mutations: {
    GET_SPACES_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_SPACES_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.spaces = data;
    },
    GET_SPACES_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.spaces = [];
    },
    GET_SPACE_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_SPACE_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.space = data;
    },
    GET_SPACE_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.space = {};
    }
  },
  actions: {
    getAllSpaces: async ({ commit }, cb) => {
      try {
        commit("GET_SPACES_REQUEST");
        const { data } = await axios.get(`/spaces`);
        commit("GET_SPACES_SUCCESS", data);
        if (cb) cb(data?.data);
      } catch (err) {
        commit("GET_SPACES_FAILURE", err);
      }
    },
    getSpace: async ({ commit }, { space, cb }) => {
      try {
        commit("GET_SPACE_REQUEST");
        const { data } = await axios.get(`/spaces/${space}`);
        commit("GET_SPACE_SUCCESS", data);
        if (cb) cb(data?.data);
      } catch (err) {
        commit("GET_SPACE_FAILURE", err);
      }
    },
    getSpaceByType: async ({ commit }, { spaceType, checkout_id, cb }) => {
      try {
        commit("GET_SPACE_REQUEST");
        const { data } = await axios.get(
          `/space-type/${spaceType}?checkout_id=${checkout_id}`
        );
        commit("GET_SPACE_SUCCESS", data);
        if (cb) cb(data?.data);
      } catch (err) {
        commit("GET_SPACE_FAILURE", err);
      }
    }
  }
};
