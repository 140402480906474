const getDefaultState = () => {
  return {
    percentCompleted: 0
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    SET_PROGRESSION(state, percentCompleted) {
      state.percentCompleted = percentCompleted;
    }
  }
};
