import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError } from "./helpers";

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    progress: 0
  },
  mutations: {
    SET_PROGRESSION(state, progress) {
      state.progress = progress;
    },
    GET_UPLOAD_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_UPLOAD_SUCCESS(state) {
      state.loading = false;
      state.error = null;
    },
    GET_UPLOAD_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
    }
  },
  actions: {
    removeTicketMedia: async ({ dispatch }, { ticket_id, upload_id }) => {
      try {
        await axios.delete(`/uploads/${upload_id}`, {
          data: {
            action: "delete_ticket_media",
            ticket_id: ticket_id
          }
        });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("media has been removed!"),
          type: "success"
        });
        dispatch("tickets/getTicket", ticket_id, { root: true });
      } catch (err) {
        showError(err);
      }
    },
    removeBikeMedia: async ({ dispatch }, { id, mediaId }) => {
      try {
        await axios.delete(`/bikes/${id}/media/${mediaId}`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("media has been removed!"),
          type: "success"
        });
        dispatch("bike/getBike", id, { root: true });
      } catch (err) {
        showError(err);
      }
    },
    upload: async ({ commit }, { payload, cb }) => {
      commit("SET_PROGRESSION", 0);
      try {
        commit("GET_UPLOAD_REQUEST");
        const { data } = await axios.post(`/uploads`, payload, {
          onUploadProgress: progressEvent => {
            commit(
              "SET_PROGRESSION",
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }
        });
        commit("GET_UPLOAD_SUCCESS", data);
        if (cb) {
          cb();
        }
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The file has been uploaded!"),
          type: "success"
        });
      } catch (err) {
        commit("GET_UPLOAD_FAILURE", err);
        showError(err);
      }
      commit("SET_PROGRESSION", 0);
    }
  }
};
