<template>
  <div class="relative md:pt-6 pb-32 pt-8">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full absolute">
      <HelpBar @getData="$emit('getData')" />
      <div class="mt-6 grid grid-cols-1 lg:grid-cols-4 gap-6 px-4">
        <div
          class="w-full h-full"
          v-for="({ name, value, unit }, index) in metrics"
          :key="`${index}${name}`"
        >
          <SlideUp>
            <div
              class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg p-4 h-full"
            >
              <div class="flex-auto p-4">
                <div class="flex flex-wrap">
                  <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                    <h5
                      class="text-blueGray-400 uppercase font-bold text-xs mb-2"
                    >
                      {{ $t(name) }}
                    </h5>
                    <span class="font-bold text-5xl text-blueGray-600">
                      {{ value }}
                    </span>
                  </div>
                  <div class="relative w-auto pl-4 flex-initial">
                    <!-- <div
                      class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500"
                    >
                      <i class="far fa-chart-bar"></i>
                    </div> -->
                  </div>
                </div>
                <p class="text-sm text-blueGray-400 -mt-2">
                  <!-- <span class="mr-2 text-emerald-500"> -->
                  <span class="mr-2 text-gray-500">
                    <!-- <i class="fas fa-arrow-up"></i> 3.48% -->
                  </span>
                  <!-- <span class="whitespace-nowrap">{{ unit }}</span> -->
                </p>
              </div>
            </div>
          </SlideUp>
        </div>
      </div>
      <!-- <div class="flex flex-wrap mt-6"> -->
      <div
        class="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6 px-4"
        style="min-height: 250px"
      >
        <div class="w-full h-full">
          <SlideUp>
            <div
              class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg p-8 h-full"
            >
              <AssetStatusPie
                :loading="$store.state.bikes.loading"
                :assets="bikes"
              />
            </div>
          </SlideUp>
        </div>
        <div class="w-full h-full">
          <SlideUp>
            <div
              class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg p-8 h-full"
            >
              <StackCol
                :damages="damages"
                :loading="$store.state.damages.loading"
              />
            </div>
          </SlideUp>
        </div>
        <div class="w-full h-full">
          <SlideUp>
            <div
              class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg p-8 h-full"
            >
              <AssetBreakageMap
                :damages="damages"
                :loading="$store.state.damages.loading"
              />
            </div>
          </SlideUp>
        </div>
      </div>
      <div
        class="mt-6 w-full h-full"
        v-if="$store.state.accounts.user.company.id == 12"
      >
        <SlideUp>
          <ListTable
            :tableHeight="250"
            :tableName="$t('tickets')"
            :headers="ticketHeaders"
            :tableData="tickets"
            :loading="$store.state.tickets.loading"
            :searchable="false"
          />
        </SlideUp>
      </div>
      <div
        class="mt-6 w-full h-full"
        v-if="$store.state.accounts.user.company.id != 12"
      >
        <SlideUp>
          <ListTable
            :tableHeight="250"
            :tableName="$t('asset damages')"
            :headers="damageHeaders"
            :tableData="damages"
            :loading="$store.state.damages.loading"
            :searchable="false"
          />
        </SlideUp>
      </div>
      <div class="mt-6 w-full h-full">
        <SlideUp>
          <ListTable
            :tableHeight="250"
            :tableName="$t('checklist submissions')"
            :headers="checkListResultsHeaders"
            :tableData="checkListResults"
            :loading="$store.state.checkListResults.loading"
            :searchable="false"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import { mapState } from "vuex";
import HelpBar from "./HelpBar.vue";
import ListTable from "@/components/ListTable.vue";
import StackCol from "./charts/StackCol.vue";
import AssetStatusPie from "./charts/AssetStatusPie.vue";
import AssetBreakageMap from "./charts/AssetBreakageMap.vue";

export default {
  name: "BoxMetrics",
  components: {
    SlideUp,
    HelpBar,
    ListTable,
    StackCol,
    AssetStatusPie,
    AssetBreakageMap
  },
  computed: {
    ...mapState("bikes", ["bikes"]),
    ...mapState("damages", ["damages"]),
    ...mapState("checkouts", ["checkouts"]),
    ...mapState("batteries", ["batteries"]),
    ...mapState("tickets", ["tickets"]),
    ...mapState("checkListResults", ["checkListResults"]),
    metrics() {
      return [
        {
          name: "Open/Closed tickets",
          value: `${this.tickets.filter(t => !t.is_closed).length} / ${
            this.tickets.filter(t => t.is_closed).length
          }`,
          unit: "Today"
        },
        // {
        //   name: "Closed tickets",
        //   value: this.tickets.filter(t => t.is_closed).length,
        //   unit: "Today"
        // },
        { name: "bookings", value: this.checkouts.length, unit: "Today" },
        {
          name: "checklist submissions",
          value: this.checkListResults.length,
          unit: "Today"
        },
        {
          name: "asset damages",
          value: this.damages.length,
          unit: "Last month"
        }
      ];
    }
  },
  data() {
    return {
      checkListResultsHeaders: [
        {
          name: "checklist",
          key: "checklist.name"
        },
        {
          name: "submission results",
          key: "id",
          icon: "fas fa-eye",
          staticName: "view",
          type: "link",
          noSort: true,
          linkInfo: {
            name: "CheckListResult",
            routeKey: "id",
            varKey: "checklist.id"
          }
        },
        {
          name: "user",
          key: "user.name",
          type: ""
        },
        {
          name: "submitted at",
          key: "inserted_at",
          type: "dateTime"
        }
        // {
        //   name: "notes",
        //   key: "notes",
        //   type: ""
        // },
        // { name: "reported", key: "inserted_at", type: "dateTime" }
      ],
      damageHeaders: [
        {
          name: "asset",
          key: "bike.name",
          type: "link",
          linkInfo: { name: "Fleet-Detail", routeKey: "id", varKey: "bike_id" }
        },
        { name: "labels", key: "tags", type: "labels" },
        {
          name: "user",
          key: "user.name",
          type: ""
        },
        {
          name: "type",
          key: "type.name"
        },
        {
          name: "ticket(s) referenced",
          type: "tickets",
          key: "tickets"
          // key: "subject"
        },
        {
          name: "notes",
          key: "notes",
          type: ""
        },
        { name: "reported", key: "inserted_at", type: "dateTime" }
      ],
      ticketHeaders: [
        {
          name: "#",
          key: "id",
          type: "link",
          linkInfo: {
            name: "Ticket-Detail",
            routeKey: "id",
            varKey: "id"
          }
        },
        // { name: "status", key: "is_closed", type: "is_closed", filter: true },
        { name: "status", key: "is_closed", type: "is_closed" },
        { name: "subject", key: "subject", type: "" },
        { name: "opened", key: "inserted_at", type: "fromNowDate" },
        {
          name: "type",
          key: "type.name",
          type: "",
          filter: true
        },
        { name: "due date", key: "due_date", type: "dueDate" },
        { name: "supplier", key: "supplier.name", type: "", filter: true },
        { name: "asset", key: "bike.name", type: "", filter: true },
        { name: "hub", key: "hub.name", type: "", filter: true },
        { name: "damages", key: "damages", type: "damages", filter: true },
        {
          name: "labels",
          key: "tags",
          type: "labels",
          filter: true,
          canEdit: true,
          editId: null,
          action: (e, data, idx) => {
            if (this.headers[idx].editId == data.id) {
              this.headers[idx].editId = null;
            } else {
              this.headers[idx].editId = data.id;
            }
            e.preventDefault();
            e.stopPropagation();
          },
          applySelection(types) {
            console.log(types);
            console.log("dsafasdf");
          },
          cancelSelection() {
            console.log("dsafasdf");
          }
        },
        {
          name: "actions",
          key: "action",
          type: "action",
          noSort: true,
          actions: [
            {
              name: "delete",
              icon: "far fa-trash-alt",
              action: (evt, ticket) => {
                evt.stopPropagation();

                const confirmStr = `${this.$t("Confirm delete ticket")}: ${
                  ticket.subject
                }`;
                this.$confirm(confirmStr).then(() => {
                  this.$store.dispatch("tickets/deleteTicket", {
                    ticket
                  });
                });
              }
            }
          ]
        }
      ]
    };
  }
};
</script>
