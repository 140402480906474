import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError } from "./helpers";
// import router from "../router";

export default {
  namespaced: true,
  state: {
    config: {}
  },
  mutations: {
    GET_SETTING_REQUEST(state) {
      state.config = {};
      state.loading = true;
      state.error = null;
    },
    GET_SETTING_SUCCESS(state, data) {
      state.config = data;
      state.loading = false;
      state.error = null;
    },
    GET_SETTING_FAILUARE(state, err) {
      state.config = [];
      state.loading = false;
      state.error = err;
    }
  },
  actions: {
    getSettings: async ({ commit }, { cb, setting }) => {
      try {
        commit("GET_SETTING_REQUEST");
        const { data } = await axios.get(`settings/${setting}`);
        commit("GET_SETTING_SUCCESS", data);
        if (cb) cb(data);
      } catch (err) {
        // showError(err);
        commit("GET_SETTING_FAILUARE", err);
      }
    },
    setAlertSettings: async ({ dispatch }, { cb, setting }) => {
      try {
        // commit("GET_SETTING_REQUEST");
        await axios.post(`settings`, { setting });
        dispatch("getSettings", { cb, setting: "alerts" });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The alerts has been saved!"),
          type: "success"
        });
        // commit("GET_SETTING_SUCCESS", data);
        // if (cb) cb(data);
      } catch (err) {
        showError(err);
        // commit("GET_SETTING_FAILUARE", err);
      }
    }
  }
};
