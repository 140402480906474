import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import { showError, mapObjectsById } from "./helpers";
import router from "../router";

export default {
  namespaced: true,
  state: {
    hub: {},
    hubs: [],
    bikes: [],
    loading: false,
    error: null
  },
  getters: {
    hubsByID(state) {
      return mapObjectsById(state.hubs);
    }
  },
  mutations: {
    GET_HUBS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_HUBS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.hubs = data;
    },
    GET_HUBS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.hubs = [];
    },
    GET_HUB_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_HUB_SUCCESS(state, { data }) {
      const { hub, bikes } = data;
      state.loading = false;
      state.error = null;
      state.hub = hub;
      state.bikes = bikes;
    },
    GET_HUB_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.hub = {};
    }
  },
  actions: {
    getAllHubs: async ({ commit }, cb) => {
      try {
        commit("GET_HUBS_REQUEST");
        const { data } = await axios.get(`/hubs`);
        commit("GET_HUBS_SUCCESS", data);
        if (cb) cb(data?.data);
      } catch (err) {
        commit("GET_HUBS_FAILURE", err);
      }
    },
    getHub: async ({ commit }, id) => {
      try {
        commit("GET_HUB_REQUEST");
        const { data } = await axios.get(`/hubs/${id}`);
        commit("GET_HUB_SUCCESS", data);
      } catch (err) {
        commit("GET_HUB_FAILURE", err);
      }
    },
    updateHub: async ({ dispatch }, hub) => {
      const { id } = hub;
      try {
        await axios.patch(`/hubs/${id}`, { hub });
        dispatch("getHub", id);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("The hub has been updated!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    },
    createHub: async ({ dispatch }, hub) => {
      try {
        const { data } = await axios.post(`/hubs`, { hub });
        // console.log(data);
        dispatch("getHub", data.data.id);
        router.push({
          name: "Hub-Detail",
          params: {
            id: data.data.id
          }
        });
      } catch (err) {
        showError(err);
      }
    },
    deleteHub: async ({ dispatch }, { hub, cb }) => {
      try {
        await axios.delete(`/hubs/${hub.id}`);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("hub has been delete!"),
          type: "success"
        });
        router.push({ name: "Hub" });
        dispatch("getAllHubs");
        if (cb) cb();
      } catch (err) {
        showError(err);
      }
    }
  }
};
