import axios from "../axios";

export default {
  namespaced: true,
  state: {
    users: [],
    loading: false,
    error: null
  },
  mutations: {
    GET_USERS_REQUEST(state) {
      state.loading = true;
      state.error = null;
      state.users = [];
    },
    GET_USERS_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.users = data;
    },
    GET_USERS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.users = [];
    }
  },
  actions: {
    getAllUsers: async ({ commit }) => {
      try {
        commit("GET_USERS_REQUEST");
        const { data } = await axios.get(`/users`);
        commit("GET_USERS_SUCCESS", data);
      } catch (err) {
        commit("GET_USERS_FAILURE", err);
      }
    }
  }
};
