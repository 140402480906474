<template>
  <span
    class="ml-3 rounded px-2 py-1 font-semibold inline-flex items-center"
    :style="`background:${color};color:${textColor}`"
  >
    {{ name }}
    <i
      v-if="hasRemove"
      class="fas fa-times cursor-pointer mx-1"
      @click="$emit('remove', label)"
    />
  </span>
</template>

<script>
import utils from "@/mixins/utils";

export default {
  name: "LabelDisplay",
  props: {
    label: {
      validator: prop => typeof prop === "object" || prop === null,
      required: true
    },
    hasRemove: {
      type: Boolean,
      default: false
    }
  },
  mixins: [utils],
  computed: {
    textColor() {
      return this.invertColor(this.color, true);
    },
    color() {
      return this.label?.color ? this.label?.color : "";
    },
    name() {
      return this.label?.name ? this.label?.name : "";
    },
    shade() {
      return this.lightOrDark(this.color);
    }
  }
};
</script>
