import moment from "moment";
import axios from "../axios";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    exportChecklistResult: async (_, checklist_id) => {
      try {
        axios({
          url: `/checklists-results/${checklist_id}/export`,
          method: "post",
          responseType: "blob",
          data: checklist_id // important
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          let unixNow = moment().unix();
          link.href = url;
          link.setAttribute("download", `checklist-results-${unixNow}.csv`);
          document.body.appendChild(link);
          link.click();
        });
      } catch (err) {
        console.log(err);
      }
    },
    exportBikes: async (_, ids) => {
      try {
        axios({
          url: "/bikes/export",
          method: "post",
          responseType: "blob",
          data: ids // important
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          let unixNow = moment().unix();
          link.href = url;
          link.setAttribute("download", `assets-${unixNow}.csv`);
          document.body.appendChild(link);
          link.click();
        });
      } catch (err) {
        console.log(err);
      }
    },
    exportAttendance: async (_, data) => {
      try {
        axios({
          url: "/schedule/attendance/export",
          method: "post",
          responseType: "blob",
          data // important
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `attendance-${new Date()}.csv`);
          document.body.appendChild(link);
          link.click();
        });
      } catch (err) {
        console.log(err);
      }
    }
  }
};
