import axios from "../axios";

export default {
  namespaced: true,
  state: {
    bikeLocks: [],
    loading: false,
    error: null
  },
  mutations: {
    GET_BIKE_LOCKS_REQUEST(state) {
      state.loading = true;
      state.error = null;
      state.bikeLocks = [];
    },
    GET_BIKE_LOCKS_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.bikeLocks = data;
    },
    GET_BIKE_LOCKS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.bikeLocks = [];
    }
  },
  actions: {
    getAllBikeLocks: async ({ commit }) => {
      try {
        commit("GET_BIKE_LOCKS_REQUEST");
        const { data } = await axios.get(`/bike-locks`);
        commit("GET_BIKE_LOCKS_SUCCESS", data);
      } catch (err) {
        commit("GET_BIKE_LOCKS_FAILURE", err);
      }
    }
  }
};
