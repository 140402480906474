import axios from "../axios";
import { getMessage } from "./helpers.js";

const getDefaultState = () => {
  return {
    loading: false,
    error: null
  };
};
const state = getDefaultState();

export default {
  namespaced: true,
  state,
  getters: {
    isAuthenticated(state) {
      return state.user.token;
    }
  },
  mutations: {
    REQUEST_PASSWORD_RESET_REQUEST(state) {
      state.error = null;
      state.loading = true;
    },
    REQUEST_PASSWORD_RESET_SUCCESS(state) {
      state.loading = false;
      state.error = null;
    },
    REQUEST_PASSWORD_RESET_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
    }
  },
  actions: {
    async sendPasswordResetLink({ commit }, { cb, payload }) {
      commit("REQUEST_PASSWORD_RESET_REQUEST");
      try {
        const { data } = await axios.post(`/users/reset_password`, payload);
        commit("REQUEST_PASSWORD_RESET_SUCCESS", data);
        if (cb)
          cb(
            "If your email is in our system, you will receive instructions to reset your password shortly."
          );
      } catch (err) {
        commit("REQUEST_PASSWORD_RESET_FAILURE", err);
      }
    },
    async passwordReset({ commit }, { cb, payload, token, errorCb }) {
      commit("REQUEST_PASSWORD_RESET_REQUEST");
      try {
        const { data } = await axios.put(
          `/users/reset_password/${token}`,
          payload
        );
        commit("REQUEST_PASSWORD_RESET_SUCCESS", data);
        if (cb) cb("Password reset successfully.");
      } catch (err) {
        commit("REQUEST_PASSWORD_RESET_FAILURE", err);
        if (errorCb) {
          const error = err?.response?.data;
          let message = error?.message;
          const errors = error?.errors;
          if (message) {
            errorCb(message, true);
          } else if (errors) {
            message = errors ? getMessage(errors) : "";
            errorCb(message, false);
          }
        }
      }
    }
  }
};
