<template>
  <div class="h-full">
    <h2 class="text-blueGray-400 uppercase font-semibold text-xs">
      {{ $t("BREAKAGE BY DAY") }}
    </h2>
    <div class="flex justify-center items-center h-full flex-grow">
      <i v-if="loading" class="fas fa-sync fa-spin" />
      <apexchart
        v-else-if="damages && damages.length"
        :key="damages.length + 'damage'"
        type="bar"
        height="350"
        :options="chart.chartOptions"
        :series="chart.series"
      />
      <h2 v-else class="font-semibold text-lg">
        🙌 {{ $t("nice, no damages") }}
      </h2>
    </div>
  </div>
</template>

<script>
import { groupByDate } from "../../store/helpers";
import moment from "moment";
export default {
  name: "StackCol",
  props: {
    loading: {
      type: Boolean,
      defualt: false
    },
    damages: {
      type: Array,
      require: true
    }
  },
  watch: {
    damages() {
      this.groupedDamages = groupByDate(this.damages, "inserted_at");
    }
  },
  computed: {
    chart() {
      let dateIndex = Object.keys(this.groupedDamages).reduce(
        (accu, date, index) => {
          accu[date] = index;
          return accu;
        },
        {}
      );
      let seen = {};
      let series = this.damages.reduce((accu, item) => {
        let name = item.type?.name;
        if (seen[name] == undefined) {
          accu.push({ name, data: Object.keys(dateIndex).map(() => 0) });
          seen[name] = accu.length - 1;
        }
        let accuIndex = seen[name];
        let dataIndex =
          dateIndex[moment(item.inserted_at).format("DD.MM.YYYY")];
        accu[accuIndex].data[dataIndex] = accu[accuIndex].data[dataIndex] + 1;
        return accu;
      }, []);
      if (series.length == 0) {
        series = [{ name: "", data: [] }];
      }
      const categories = Object.keys(this.groupedDamages);
      return {
        series,
        chartOptions: {
          chart: {
            type: "bar",
            stacked: true,
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: "thin",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: true
            }
          },
          legend: {
            position: "bottom",
            offsetY: 0
          },
          responsive: [
            {
              breakpoint: 480,
              options: {}
            }
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10
            }
          },
          xaxis: {
            type: "string",
            categories
          },
          fill: {
            opacity: 1
          }
        }
      };
    }
  },
  data() {
    return {
      groupedDamages: {}
    };
  },
  created() {
    this.groupedDamages = groupByDate(this.damages, "inserted_at");
  }
};
</script>
