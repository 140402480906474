<template>
  <div class="flex items-center">
    <div class="hidden md:block">
      <LangSelect />
    </div>
    <div class="flex flex-col text-right mr-1 cursor-pointer">
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link flex items-center">
          {{ me.name }}
          <div class="ml-2">
            <img class="rounded h-6 w-6 object-cover" v-if="url" :src="url" />

            <div
              v-else-if="initials != ''"
              class="uppercase rounded h-8 w-8 object-cover bg-blueGray-500 text-blueGray-50 font-bold flex items-center justify-center bg-gradient-to-r from-gray-800 to-blueGray-600"
            >
              {{ initials }}
            </div>
            <i v-else class="fas fa-user-circle" />
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="user-settings">
            {{ $t("user settings") }}
          </el-dropdown-item>
          <el-dropdown-item command="logout" divided>
            {{ $t("logout") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import LangSelect from "@/components/LangSelect";
import userMixins from "@/mixins/userMixins";
export default {
  name: "SubNav",
  components: { LangSelect },
  mixins: [userMixins],
  computed: {
    url() {
      return this.me?.profile_image;
    },
    initials() {
      return this.getInitials(this.me?.name);
    },
    me() {
      return this.$store.state?.accounts.user;
    }
  },
  methods: {
    handleCommand(command) {
      if (command == "logout") {
        this.$store.dispatch("accounts/logout");
      } else if (command == "user-settings") {
        this.$router.push({ name: "UserSettings" });
      }
    }
  }
};
</script>
