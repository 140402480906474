import axios from "../axios";

export default {
  namespaced: true,
  state: {
    groups: [],
    loading: false,
    error: null
  },
  mutations: {
    GET_GROUPS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_GROUPS_SUCCESS(state, { data }) {
      state.loading = false;
      state.error = null;
      state.groups = data;
    },
    GET_GROUPS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.groups = [];
    }
  },
  actions: {
    getGroups: async ({ commit }) => {
      try {
        commit("GET_GROUPS_REQUEST");
        const { data } = await axios.get(`/groups`);
        commit("GET_GROUPS_SUCCESS", data);
      } catch (err) {
        commit("GET_GROUPS_FAILURE", err);
      }
    },
    createGroup: async ({ dispatch }, { group, cb }) => {
      try {
        await axios.post(`/groups`, { group });
        dispatch("getGroups");
        if (cb) cb();
      } catch (err) {
        //
      }
    },
    updateGroup: async ({ dispatch }, { group, cb }) => {
      try {
        await axios.put(`/groups/${group.id}`, { group });
        dispatch("getGroups");
        if (cb) cb();
      } catch (err) {
        //
      }
    },
    addUsers: async ({ dispatch }, { group, user }) => {
      try {
        await axios.put(`/groups/${group.id}`, {
          group: {
            action: "ADD_USER",
            user: user.id
          }
        });
        dispatch("getGroups");
      } catch (err) {
        //
      }
    },
    deleteGroup: async ({ dispatch }, { group }) => {
      try {
        await axios.delete(`/groups/${group.id}`);
        dispatch("getGroups");
      } catch (err) {
        //
      }
    }
  }
};
