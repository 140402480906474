import i18n from "../i18n";
import { Notification } from "element-ui";
import moment from "moment";

export const mapObjectsById = objects => {
  return objects.reduce((accu, object) => {
    accu[object.id] = object;
    return accu;
  }, {});
};
export const getMessage = items =>
  Object.entries(items)
    .reduce((accu, [key, value]) => {
      if (Array.isArray(value)) {
        value = value.join();
      } else if (typeof value === "object") {
        value = getMessage(value);
      }
      return ` ${accu} ${key} ${value},`;
    }, "")
    .slice(0, -1);
// slice to remove the last ,

export const showError = err => {
  const error = err?.response?.data;
  let message = error;
  const errors = error?.errors;
  if (errors) {
    message = errors ? getMessage(errors) : "";
  }

  Notification.error({
    title: i18n.t("Error"),
    message
  });
};

export const attachFromAndToUrl = (url, dateRange, hubs) => {
  let [from, to] = dateRange ? dateRange : [null, null];
  let concater = "?";
  if (from != null) {
    from = moment(from).startOf("day").unix();
    url = `${url}${concater}from=${from}`;
    concater = "&";
  }
  if (to != null) {
    to = moment(to).endOf("day").unix();
    url = `${url}${concater}to=${to}`;
    concater = "&";
  }
  if (hubs != null) {
    url = `${url}${concater}hubs=${hubs.join(",")}`;
  }
  return url;
};
export const attachHubUrl = (url, hubs) => {
  if (hubs != null) {
    url = `${url}?hubs=${hubs.join(",")}`;
  }
  return url;
};

export const groupByDate = (items, key) => {
  return items.reduce((accu, item) => {
    const date = moment(item[key]).format("DD.MM.YYYY");
    if (!(date in accu)) {
      accu[date] = [];
    }
    accu[date].push(item);
    return accu;
  }, {});
};
