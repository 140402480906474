import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import router from "../router";
import { showError } from "./helpers";

const getDefaultState = () => {
  return {
    battery: {},
    batteries: [],
    loading: false,
    error: null
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    RESET_BATTERIES(state) {
      Object.assign(state, getDefaultState());
    },
    GET_BATTERY_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_BATTERY_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.battery = data;
    },
    GET_BATTERY_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.battery = {};
    },
    GET_BATTERIES_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_BATTERIES_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.batteries = data;
    },
    GET_BATTERIES_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.batteries = [];
    }
  },
  actions: {
    getAllBatteries: async ({ commit }) => {
      try {
        commit("GET_BATTERIES_REQUEST");
        const { data } = await axios.get(`/batteries`);
        commit("GET_BATTERIES_SUCCESS", data);
      } catch (err) {
        commit("GET_BATTERIES_FAILURE", err);
      }
    },
    getBattery: async ({ commit }, id) => {
      try {
        commit("GET_BATTERY_REQUEST");
        const { data } = await axios.get(`/batteries/${id}`);
        commit("GET_BATTERY_SUCCESS", data);
      } catch (err) {
        commit("GET_BATTERY_FAILURE", err);
      }
    },
    createBattery: async ({ dispatch }, battery) => {
      try {
        //
        const { data } = await axios.post(`/batteries`, { battery });
        dispatch("getBattery", data.id);
        router.push({
          name: "Battery-Detail",
          params: {
            id: data.id
          }
        });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your battery has been created!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    },
    updateBattery: async ({ dispatch }, battery) => {
      const { id } = battery;
      try {
        //
        await axios.patch(`/batteries/${id}`, { battery });
        dispatch("getBattery", id);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your battery has been updated!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    }
  }
};
