import axios from "../axios";
import { Notification } from "element-ui";
import i18n from "../i18n";
import router from "../router";
import { showError } from "./helpers";

const getDefaultState = () => {
  return {
    asset: {},
    assets: [],
    loading: false,
    error: null
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations: {
    RESET_ASSET(state) {
      Object.assign(state, getDefaultState());
    },
    GET_ASSETS_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_ASSETS_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.assets = data;
    },
    GET_ASSETS_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.assets = [];
    },
    GET_ASSET_REQUEST(state) {
      state.loading = true;
      state.error = null;
    },
    GET_ASSET_SUCCESS(state, data) {
      state.loading = false;
      state.error = null;
      state.asset = data;
    },
    GET_ASSET_FAILURE(state, error) {
      state.loading = false;
      state.error = error;
      state.asset = {};
    }
  },
  actions: {
    getAllAssets: async ({ commit }) => {
      try {
        commit("GET_ASSETS_REQUEST");
        const { data } = await axios.get(`/assets`);
        commit("GET_ASSETS_SUCCESS", data);
      } catch (err) {
        commit("GET_ASSETS_FAILURE", err);
      }
    },
    getAsset: async ({ commit }, id) => {
      try {
        commit("GET_ASSET_REQUEST");
        const { data } = await axios.get(`/assets/${id}`);
        commit("GET_ASSET_SUCCESS", data);
      } catch (err) {
        commit("GET_ASSET_FAILURE", err);
      }
    },
    createAsset: async ({ dispatch }, asset) => {
      try {
        //
        const { data } = await axios.post(`/assets`, { asset });
        dispatch("getAsset", data.id);
        router.push({
          name: "Asset-Detail",
          params: {
            id: data.id
          }
        });
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your asset has been created!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    },
    updateAsset: async ({ dispatch }, asset) => {
      const { id } = asset;
      try {
        //
        await axios.patch(`/assets/${id}`, { asset });
        dispatch("getAsset", id);
        Notification({
          title: i18n.t("Success"),
          message: i18n.t("Your asset has been updated!"),
          type: "success"
        });
      } catch (err) {
        showError(err);
      }
    }
  }
};
