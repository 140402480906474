<template>
  <div class="block transition-all duration-1000">
    <nav
      class="border-r px-3 md:px-0 md:left-0 md:py-0 md:block md:fixed md:top-0 md:bottom-0 md:flex-row md:flex-nowrap md:overflow-hidden py-4 shadow-2xl bg-white flex flex-wrap items-center justify-between fixed w-full top-0 z-50"
      :class="{ 'md:w-14': !showSideNav, 'md:w-64': showSideNav }"
    >
      <div
        class="flex px-2 items-center justify-center"
        style="max-height: 75px"
        :class="{
          'md:border-b': !showSideNav
        }"
      >
        <span class="md:hidden" @click="drawer = true">
          <i class="fas fa-bars mr-4" />
        </span>
        <router-link
          ref="logoarea"
          to="/"
          class="text-center text-blueGray-600 whitespace-nowrap text-sm font-bold flex justify-center items-center"
          :class="{
            'md:pt-5 md:pb-4': !showSideNav,
            'md:pr-10 md:pt-9': showSideNav
          }"
        >
          <!-- <img
            alt="vault logo"
            width="60"
            class="inline"
            src="@/assets/vault_transparent_dark_logo.png"
          />
          <i class="fas fa-times px-1 text-orange-600" /> -->
          <img v-if="logo" width="100" :src="logo" />
          <span v-else class="text-xl text-orange-600">{{ partner }}</span>
          <!-- <span class="logofont"> VAULT </span> -->
        </router-link>
      </div>
      <AccountSettingDropdown class="md:hidden" />
      <div
        class="overflow-auto hidden md:block"
        :style="`height: calc(100vh - ${height}px)`"
        :class="{ 'md:mt-6': !showSideNav }"
      >
        <div
          v-for="({ items, label }, i) in navItems"
          :key="label"
          class="mb-5"
          :class="{ 'border-blueGray-200 border-t': i != 0 }"
        >
          <p
            class="mt-5 mb-1 pt-2 pl-7 font-semibold text-gray-400"
            v-if="showSideNav"
          >
            {{ $t(label) }}
          </p>
          <ul
            class="hidden md:flex md:flex-col md:min-w-full flex-col list-none"
          >
            <li
              class="items-center border-l-8 border-white mb-2"
              :class="{
                'border-cyan-500': $route.name == name,
                'px-3': showSideNav
              }"
              v-for="{ name, label, icon, space } in items"
              :key="name"
            >
              <router-link :to="{ name, params: { space } }">
                <span
                  :class="{ 'bg-gray-100': $route.name == name }"
                  class="text-sm hover:bg-gray-100 rounded px-3 uppercase py-3 font-bold block text-blueGray-700 transition duration-500 ease-in-out transform hover:text-blueGray-500 hover:translate-x-2"
                >
                  <el-tooltip
                    v-if="!showSideNav"
                    class="item"
                    effect="dark"
                    :content="$t(label)"
                    placement="right"
                  >
                    <i
                      class="mr-2 text-base text-blueGray-300 w-4"
                      :class="icon"
                    />
                  </el-tooltip>
                  <template v-if="showSideNav">
                    <i
                      class="mr-2 text-base text-blueGray-300 w-4"
                      :class="icon"
                    />
                    <span>
                      {{ $t(label) }}
                    </span>
                  </template>
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- </div> -->
      <div
        class="fixed bottom-0 left-0 px-3 py-2 border-t border-r hidden bg-white md:flex text-center justify-center items-center"
        ref="copyright"
        :class="{ 'md:w-14': !showSideNav, 'md:w-64': showSideNav }"
      >
        <div>
          <img
            alt="vault logo"
            width="50"
            class="inline"
            src="@/assets/vault_transparent_ukraine_flag_logo.png"
          />
        </div>
        <p
          class="text-xs text-blueGray-500 font-semibold flex flex-col text-left ml-2"
          v-if="showSideNav"
        >
          <span>{{ $t("Copyright © ") }} {{ currentYear }}. </span>
          <span class="-mt-1">{{ $t("vault solutions.") }}</span>
        </p>
      </div>
    </nav>
    <el-drawer :visible.sync="drawer" direction="ltr" size="53%">
      <div class="absolute top-10 left-6">
        <LangSelect />
      </div>
      <div class="overflow-auto md:block" :style="`height: calc(100vh - 82px)`">
        <div
          v-for="({ items, label }, i) in navItems"
          :key="label"
          class="mb-5"
          :class="{ 'border-blueGray-200 border-t': i != 0 }"
        >
          <p class="mt-5 mb-1 pt-2 pl-7 font-semibold text-gray-400">
            {{ $t(label) }}
          </p>
          <ul class="md:flex md:flex-col md:min-w-full flex-col list-none">
            <li
              class="items-center px-3 border-l-8 border-white mb-2"
              :class="{ 'border-cyan-500': $route.name == name }"
              v-for="{ name, label, icon, space } in items"
              :key="name"
              @click="drawer = false"
            >
              <router-link :to="{ name, params: { space } }">
                <span
                  :class="{ 'bg-gray-100': $route.name == name }"
                  class="text-sm hover:bg-gray-100 rounded px-3 uppercase py-3 font-bold block text-blueGray-700 transition duration-500 ease-in-out transform hover:text-blueGray-500 hover:translate-x-2"
                >
                  <i
                    class="mr-2 text-base text-blueGray-300 w-4"
                    :class="icon"
                  />
                  {{ $t(label) }}
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import LangSelect from "@/components/LangSelect";
import AccountSettingDropdown from "./AccountSettingDropdown.vue";
export default {
  name: "Nav",
  components: { AccountSettingDropdown, LangSelect },
  computed: {
    showSideNav() {
      return this.$store.state.accounts?.user?.info?.showSideNav;
    },
    partner() {
      let company = this.$store.state?.accounts?.user?.company?.name;
      if (company) {
        company = company.split(" ");
        return company[0];
      }
      return "You";
    },
    logo() {
      return this.$store.state?.accounts?.user?.company?.logo?.path;
    },
    superuser() {
      return this.$store.state?.accounts?.user?.is_superuser;
    },
    accountType() {
      if (this.superuser) return "admin";
      return this.$store.state?.accounts?.user?.account_type
        ? this.$store.state?.accounts?.user?.account_type
        : "";
    },
    navItems() {
      let general = {
        label: "general",
        items: [
          {
            name: "Home",
            label: "dashboard",
            icon: "fas fa-chart-line"
          },
          {
            name: "Damage",
            label: "Asset Damages",
            emoji: "🚲",
            icon: "fas fa-exclamation-circle",
            show: ["manager", "admin", "supplier"]
          },
          {
            name: "Ticket",
            emoji: "🎫",
            label: "ticket",
            icon: "fas fa-ticket-alt",
            show: ["manager", "admin", "supplier"]
          }
        ]
      };
      if (this.$store.state.accounts.user.company.id == 3) {
        general = {
          label: "general",
          items: [
            {
              name: "Home",
              label: "dashboard",
              icon: "fas fa-chart-line"
            },
            {
              name: "Booking",
              label: "bookings",
              icon: "fas fa-book-open"
            },
            {
              name: "Damage",
              label: "Asset Damages",
              emoji: "🚲",
              icon: "fas fa-exclamation-circle",
              show: ["manager", "admin", "supplier"]
            },
            {
              name: "Ticket",
              emoji: "🎫",
              label: "ticket",
              icon: "fas fa-ticket-alt",
              show: ["manager", "admin", "supplier"]
            }
          ]
        };
      }
      if (this.$store.state.accounts.user.company.id == 12) {
        general = {
          label: "general",
          items: [
            {
              name: "Home",
              label: "dashboard",
              icon: "fas fa-chart-line"
            },
            {
              name: "Ticket",
              emoji: "🎫",
              label: "ticket",
              icon: "fas fa-ticket-alt",
              show: ["manager", "admin", "supplier"]
            }
          ]
        };
      }
      const settings = ["manager", "admin"].includes(this.accountType)
        ? {
            label: "settings",
            items: [
              {
                name: "Label",
                label: "labels",
                icon: "fas fa-tag"
              },
              {
                name: "TypeDefinition",
                label: "type definitions",
                icon: "fas fa-border-style"
              },
              {
                name: "Group",
                label: "groups",
                icon: "fas fa-layer-group"
              },
              {
                name: "Alert",
                label: "alerts",
                icon: "fas fa-bell"
              },
              {
                name: "QrCode",
                label: "QR Codes",
                icon: "fas fa-qrcode"
              }
              // {
              //   name: "EmailTemplate",
              //   label: "Email Templates",
              //   icon: "fas fa-envelope-open-text"
              // }
              // {
              //   name: "GoogleConnect",
              //   label: "Google Sheets",
              //   icon: "fab fa-google-drive",
              //   show: ["admin"]
              // }
            ]
          }
        : {};
      let adminItems = [
        {
          name: "Fleet",
          label: "Assets",
          emoji: "🚲",
          icon: "fas fa-box",
          show: ["manager", "admin", "supplier"]
        },
        {
          name: "Hub",
          label: "hubs",
          emoji: "⚓",
          icon: "fas fa-map-marker-alt",
          show: ["manager", "admin", "supplier"]
        },
        // {
        //   name: "Battery",
        //   label: "batteries",
        //   emoji: "🔋",
        //   icon: "fas fa-car-battery",
        //   show: ["manager", "admin"]
        // },
        // {
        //   name: "Asset",
        //   label: "assets",
        //   emoji: "🗜️",
        //   icon: "fas fa-boxes",
        //   show: ["manager", "admin"]
        // },
        {
          name: "CheckList",
          label: "checkList",
          emoji: "📜",
          icon: "fas fa-tasks",
          show: ["manager", "admin"]
        },
        {
          name: "Supplier",
          label: "suppliers",
          emoji: "🧑‍💼",
          icon: "fas fa-truck-loading",
          show: ["manager", "admin", "supplier"]
        },
        // {
        //   name: "Map",
        //   label: "gps live location",
        //   icon: "fas fa-globe-europe",
        //   show: ["manager", "admin"]
        // },
        {
          name: "User",
          emoji: "👤",
          label: "users",
          icon: "fas fa-user-friends",
          show: ["manager", "admin"]
        }
        // {
        //   name: "CheckoutMonitoring",
        //   label: "service histories",
        //   icon: "fas fa-calendar-alt",
        //   show: ["manager", "admin", "rider"]
        // }
        //{
        //   name: "CheckoutMonitoring",
        //   label: "print qrs",
        //   icon: "fas fa-qrcode",
        //   show: ["manager", "admin"]
        //},
        //{
        //  name: "Schedule",
        //  label: "Schedule",
        //  icon: "fas fa-clipboard-list",
        //  show: ["manager", "admin", "rider"]
        //},
      ];
      if (
        this.$store.state.accounts.user.company?.custom_data?.custom_modules &&
        this.$store.state.accounts.user.company?.custom_data?.custom_modules.includes(
          "invoice"
        )
      ) {
        adminItems.push({
          name: "Invoices",
          emoji: "👤",
          label: "invoices",
          icon: "fas fa-file-invoice-dollar",
          show: ["manager", "admin"]
        });
      }

      let administration = {
        label: "administration",
        items: adminItems.filter(({ show }) => show.includes(this.accountType))
      };

      if (this.$store.state.spaces.spaces.length > 0) {
        administration = {
          ...administration,
          items: this.$store.state.spaces.spaces
            .map(space => ({
              name: "Space",
              label: space.name.toLowerCase(),
              space: `${space.name.split(" ").join("_").toLowerCase()}-${
                space.id
              }`,
              show: ["manager", "admin", "rider"]
            }))
            .concat(administration.items)
        };
      }
      return [general, administration, settings];
    }
  },
  data() {
    const today = new Date();
    return {
      currentYear: today.getFullYear(),
      // partner: process.env.VUE_APP_PARTNER
      //   ? process.env.VUE_APP_PARTNER
      //   : "You",
      height: 115,
      drawer: false
    };
  },
  mounted() {
    this.height =
      this.$refs.logoarea.clientHeight + this.$refs.copyright.clientHeight;
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Fredoka+One&family=Josefin+Sans:wght@600&family=Jost:wght@500&family=Noto+Sans+JP:wght@500&family=Poppins:wght@100&family=Varela+Round&family=Zilla+Slab:wght@300&display=swap");

.logofont {
  /* font-family: "Cairo", sans-serif;
  font-family: "Fredoka One", cursive;
  font-family: "Josefin Sans", sans-serif; */
  /* font-family: "Jost", sans-serif; */
  /* font-family: "Noto Sans JP", sans-serif; */
  font-family: "Varela Round", sans-serif;
  font-size: 3rem;
}
</style>
